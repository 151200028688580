.news-wrapper {
    .news-img {
        max-width: 100%;
        // height: 100%;
        height: 288px;
        object-fit: cover;
        @media screen and (max-width: 599px) {
            height: 141px;
            width: 100%;
        }
    }
    .img-heading {
        position: absolute;
        left: 16px;
        right: 16px;
        bottom: 16px;
        color: $base-white;
        .img-head {
            font-size: 24px;
            line-height: 31px;
            letter-spacing: 0.03em;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            @media screen and (max-width: 599px) {
                font: 500 20px/26px ContinentalStagSansW-M;
            }
        }
        .img-date-time {
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            color: rgba(255, 255, 255, 0.9);
            margin-top: 16px;
            &.dark {
                color: $base-black;
            }
        }
    }
    .date-time {
        color: $base-black-t40;
        font-size: 12px;
        line-height: 12px;
    }
    .heading {
        font: 500 16px/18px ContinentalStagSansW-M;
        letter-spacing: 0.01em;
        color: $base-black;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-top: 8px;
        // padding-right: 10px;
        cursor: pointer;
    }
    .newsBlock {
        padding-top: 16px;
        padding-bottom: 16px;
        border-bottom: 1px solid #d0cece;
        &:first-child {
            padding-top: 12.5px;
        }
        &:nth-last-child(1) {
            border-bottom: none;
        }
    }
    .widget_cta_button {
        @media screen and (max-width: 899px) {
            background: $base-white;
            left: 0;
            right: 0;
            display: flex;
            justify-content: flex-end;
            bottom: 0;
            padding: 0px 24px 24px;
            border-radius: 24px;
        }
    }
}
