/** @format */

.page_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  &_topicsSubHeading {
    margin-top: 24px;
    font-weight: 400;
  }
  // &.dashboard_header {
  //   margin-bottom: 45px;

  //   @include media-md {
  //     margin-bottom: 10px;
  //   }
  // }
  h1 {
    color: $base-black;
  }
  .customButton.addicon {
    margin-left: 21px;

    @include media-md {
      margin-left: 6px;
    }
  }
  // @include media-sm {
  //   margin-bottom: 20px;
  // }
}
