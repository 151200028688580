/** @format */

.loader-progress {
  width: 80px;
  height: 80px;
  display: inline-block;
  position: relative;
  &::after,
  &::before {
    content: '';
    box-sizing: border-box;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background: #ffa500;
    position: absolute;
    left: 0;
    top: 0;
    transform: scale(0);
    opacity: 1;
    animation: animLoader 2s linear infinite;
  }
  &::after {
    animation-delay: 1s;
  }
}

@keyframes animLoader {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}
