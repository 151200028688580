/** @format */

.switchLabel {
  &.MuiFormControlLabel-root {
    margin-left: 0;
    pointer-events: none;
    .MuiTypography-root {
      font: 400 17px/25px ContinentalStagSansW;
      color: $base-black;
    }
    span {
      pointer-events: auto;
    }
  }
}

.radioLabel {
  font: 500 16px/21px ContinentalStagSansW;
  color: $base-black;
  cursor: pointer;
}

.checkbox_box.MuiFormGroup-root {
  display: grid;
  grid-template-columns: 2.4rem 1fr;
  align-items: center;
  column-gap: 0.8rem;
  .MuiCheckbox-root,
  .MuiRadio-root {
    padding: 0;
  }
}

.checkboxLabel {
  .MuiFormControlLabel-label {
    font: 500 16px/21px ContinentalStagSansW;
  }
}

.customButton {
  &.label-button {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    cursor: pointer;
    span {
      display: flex;
      line-height: 50px;
      gap: 16px;
      align-items: center;
    }
  }
  &.MuiButton-contained {
    height: 50px;
    font: 500 16px/16px ContinentalStagSansW-M;
    letter-spacing: 0.02em;
    text-transform: initial;
    border-radius: 40px;
    padding: 0 24px;
    overflow: hidden;
    z-index: 1;
    @include transition(all, 0.5s, ease);
    box-shadow: none;
    span {
      &.MuiButton-endIcon {
        margin-left: 20.5px;
        margin-right: 4px;
        @include transition(all, 0.5s, ease);
      }
      &.MuiButton-startIcon {
        margin-right: 20.5px;
        margin-left: 4px;
        @include transition(all, 0.5s, ease);
      }
    }
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 50px;
      width: 0;
      background-color: $primary1-yellow-500;
      z-index: -1;
      @include transition(all, 0.5s, ease);

      &:active {
        background-color: $primary1-yellow-700;
      }
    }
    &:hover {
      background-color: transparent;
      box-shadow: none;
      @include transition(all, 0.5s, ease);
      span {
        &.MuiButton-endIcon {
          margin-left: 12px;
          @include transition(all, 0.5s, ease);
        }
      }
      span {
        &.MuiButton-startIcon {
          margin-right: 12px;
          @include transition(all, 0.5s, ease);
        }
      }
      &::before {
        width: 500px;
      }
      svg {
        path {
          fill: $base-black;
        }
      }
    }
    &:active {
      color: $base-black;
      background-color: $primary1-yellow-700 !important;
      box-shadow: none;
      &::before {
        background-color: $primary1-yellow-700;
      }
    }
  }
  &.dark {
    background-color: $base-black;
    color: $base-white;
    &:hover {
      color: $base-black;
      span {
        svg {
          fill: $base-black;
        }
      }
    }
    &.Mui-disabled {
      color: $base-white-t60;
      background-color: $base-black-t10;
      span {
        svg {
          fill: $base-white-t60;
        }
      }
    }
  }
  &.light {
    background-color: $base-white;
    color: $base-black !important;
    // &:hover {
    //     color: $base-white;
    // }
    &.Mui-disabled {
      color: $base-black-t25 !important;
      background-color: $base-white-t20;
      span {
        svg {
          fill: $base-black-t25;
        }
      }
    }
  }
  &.transLabel {
    box-shadow: none;
    background: transparent;
    padding: 0;
    @include transition(all, 0.5s, ease);
    span {
      &.MuiButton-startIcon {
        margin-right: 20.5px;
        margin-left: 0;
      }
      &.MuiButton-endIcon {
        margin-left: 20.5px;
        margin-right: 0;
      }
      &.MuiButton-startIcon,
      &.MuiButton-endIcon {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50px;
        width: 50px;
        border-radius: 50%;
      }
    }
    &.dark {
      color: $base-black;
      @include transition(all, 0.5s, ease);
      &:hover {
        color: $base-black;
      }
      &.startTrans {
        &:hover {
          padding: 0 24px 0 0;
          @include transition(all, 0.5s, ease);
          .MuiButton-startIcon {
            background: transparent;
            margin-right: 0;
            @include transition(all, 0.5s, ease);
          }
        }
      }
      &.endTrans {
        &:hover {
          padding: 0 0 0 24px;
          @include transition(all, 0.5s, ease);
          .MuiButton-endIcon {
            background: transparent;
            margin-left: -4px;
            @include transition(all, 0.5s, ease);
          }
        }
      }
      span {
        &.MuiButton-endIcon {
          background-color: $base-black;
          @include transition(all, 0.5s, ease);
        }
        &.MuiButton-startIcon {
          background-color: $base-black;
          @include transition(all, 0.5s, ease);
        }
      }
      &.Mui-disabled {
        color: $base-black-t10;
        background-color: transparent;
        span {
          background-color: $base-black-t10;
        }
      }
    }
    &.light {
      color: $base-white;
      @include transition(all, 0.5s, ease);
      &.startTrans {
        &:hover {
          color: $base-black;
          padding: 0 24px 0 0;
          .MuiButton-startIcon {
            background: transparent;
            margin-right: 0;
            @include transition(all, 0.5s, ease);
          }
        }
      }
      &.endTrans {
        &:hover {
          color: $base-black;
          padding: 0 0 0 24px;
          .MuiButton-endIcon {
            background: transparent;
            margin-left: -4px;
            @include transition(all, 0.5s, ease);
          }
        }
      }
      span {
        &.MuiButton-endIcon {
          background-color: $base-white;
          @include transition(all, 0.5s, ease);
        }
        &.MuiButton-startIcon {
          background-color: $base-white;
          @include transition(all, 0.5s, ease);
        }
      }
      &.Mui-disabled {
        color: $base-white-t20;
        background-color: transparent;
        span {
          background-color: $base-white-t20;
        }
      }
    }
  }
  &.noLabel {
    padding: 0;
    min-width: auto;
    margin: 15px 0;
    overflow: initial;
    &::before {
      display: none;
    }
    span {
      margin: 0 !important;
      &.MuiButton-endIcon,
      &.MuiButton-startIcon {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 80px;
        width: 80px;
        border-radius: 50%;
        @include elevation-1;
      }
    }
    &.dark {
      span {
        &.MuiButton-endIcon,
        &.MuiButton-startIcon {
          background-color: $base-black;
          border: 4px solid $primary2-pastel-100;
          @include transition(all, 0.5s, ease);
          &:hover {
            background-color: $primary1-yellow-500;
            @include transition(all, 0.5s, ease);
          }
          &:active {
            background-color: $primary1-yellow-700;
          }
        }
      }
      &.Mui-disabled {
        background-color: transparent;
        span {
          background-color: $base-black-t10;
          border-color: #f0f0ee;
          box-shadow: none;
        }
      }
    }
    &.light {
      span {
        &.MuiButton-endIcon,
        &.MuiButton-startIcon {
          background-color: $base-white;
          border: 4px solid $primary2-pastel-300;
          @include transition(all, 0.5s, ease);
          &:hover {
            border-color: $primary1-yellow-500;
            @include transition(all, 0.5s, ease);
          }
          &:active {
            border-color: $primary1-yellow-700;
          }
        }
      }
      &.Mui-disabled {
        background-color: transparent;
        span {
          background-color: $base-white-t20;
          border-color: $primary2-pastel-300;
          box-shadow: none;
        }
      }
    }
  }
  &.small-tailless {
    height: initial;
    box-shadow: none !important;
    span.MuiButton-endIcon {
      height: 32px;
      width: 32px;
      border: none !important;
      box-shadow: none;
      padding-right: 2px;
    }
    &:hover {
      span.MuiButton-endIcon {
        background-color: $primary1-yellow-500;
      }
    }
    &:active {
      span.MuiButton-endIcon {
        background-color: $primary1-yellow-700;
      }
    }
    &.light {
      &.Mui-disabled {
        span {
          background-color: $base-white-t20;
        }
      }
    }
  }
  &.in-noti-setting {
    background-color: transparent;
  }
  &.borderless {
    box-shadow: none !important;
    span.MuiButton-endIcon {
      height: 50px;
      width: 50px;
      border: none !important;
      box-shadow: none;
    }
    &:hover {
      span.MuiButton-endIcon {
        background-color: $primary1-yellow-500;
      }
    }
    &:active {
      span.MuiButton-endIcon {
        background-color: $primary1-yellow-700;
      }
    }
    &.light {
      &.Mui-disabled {
        span {
          background-color: $base-white-t20;
        }
      }
    }
  }
  &.disabled {
    pointer-events: none;
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.outlined-button {
  &.MuiButton-outlined {
    text-transform: initial;
    border: 1px solid $neutrals-gray-700;
    border-radius: 40px;
    color: $base-black;
    font: 500 16px/16px ContinentalStagSansW-M;
    letter-spacing: 0.02em;
    height: 50px;
    padding: 17px 24px;
    &:hover {
      background: none;
      border-color: $primary1-yellow-500;
      @include transition(all, 0.5s, ease);
      color: $primary1-yellow-500;
    }
    &:active {
      border-color: $primary1-yellow-700;
    }
  }
}

.close-button {
  &.MuiButton-root {
    text-transform: initial;
    font: 400 18px/29px ContinentalStagSansW;
    padding: 4px;
    @include transition(all, 0.5s, ease);
    &.MuiButtonBase-root {
      &:hover {
        background: none;
        color: $primary1-yellow-500;
        @include transition(all, 0.5s, ease);
        svg {
          fill: $primary1-yellow-500;
          @include transition(all, 0.5s, ease);
        }
      }
      &:active {
        color: $primary1-yellow-700;
        svg {
          fill: $primary1-yellow-700;
        }
      }
    }
    &.dark {
      color: $base-black;
      svg {
        fill: $base-black;
        @include transition(all, 0.5s, ease);
      }
      &.Mui-disabled {
        color: $base-black-t10;
        svg {
          fill: $base-black-t10;
        }
      }
    }
    &.light {
      color: $base-white;
      svg {
        fill: $base-white;
        @include transition(all, 0.5s, ease);
      }
      &.Mui-disabled {
        color: $base-white-t20;
        svg {
          fill: $base-white-t20;
        }
      }
    }
  }
}

.link-text {
  &.MuiLink-root {
    color: $base-black;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    text-decoration-color: inherit;
    cursor: pointer;
    @include transition(all, 0.5s, ease);
    &:hover {
      color: $primary1-yellow-500;
      @include transition(all, 0.5s, ease);
    }
    &:active {
      color: $primary1-yellow-700;
    }
  }
}

.link-text-white {
  &.MuiLink-root {
    color: $base-white;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    text-decoration-color: inherit;
    @include transition(all, 0.5s, ease);
    &:hover {
      color: $primary1-yellow-500;
      @include transition(all, 0.5s, ease);
    }
    &:active {
      color: $primary1-yellow-700;
    }
  }
}

.customPaginator {
  .MuiMobileStepper-dot {
    background: rgba(0, 0, 0, 0.1);
    transition: width 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
  .MuiMobileStepper-dotActive {
    width: 24px;
    border-radius: 16px;
    background-color: $base-black;
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
}

.customPagination-dark {
  .MuiPaginationItem-root {
    font-size: 0;
    color: transparent;
    background-color: #d8d8d8;
    height: 7px;
    width: 7px;
    min-width: initial;
    padding: 0;
    &.Mui-selected {
      background-color: $base-black;
      color: transparent;
      width: 24px;
      border-radius: 16px;
      &:hover {
        background-color: $base-black;
      }
    }
  }
}

.customPagination-light {
  .MuiPaginationItem-root {
    font-size: 0;
    color: transparent;
    background-color: #f3f3f3;
    height: 7px;
    width: 7px;
    min-width: initial;
    padding: 0;
    &.Mui-selected {
      background-color: $base-white;
      color: transparent;
      width: 24px;
      border-radius: 16px;
      &:hover {
        background-color: $base-white;
      }
    }
  }
}

.autoComplete-wrapper {
  .autoComplete {
    .MuiFormControl-root,
    .MuiFormLabel-root,
    .MuiInputBase-input {
      font-size: 1.6rem;
      cursor: pointer;
    }
    .MuiFormLabel-root.Mui-focused,
    .MuiFormLabel-root.MuiFormLabel-filled {
      top: -10px;
    }
    .MuiFormControl-root {
      &:hover {
        .MuiFormLabel-root:not(.Mui-error):not(.Mui-disabled) {
          color: $primary1-yellow-500;
        }
      }
      .MuiOutlinedInput-root:not(.Mui-disabled):not(.Mui-error) {
        &:hover fieldset {
          border: 1px solid $primary1-yellow-800;
        }
      }
      .Mui-error {
        fieldset {
          border-color: $red-300;
        }
        &:hover fieldset {
          border-color: $red-300;
        }
      }
      fieldset {
        top: 0;

        legend {
          display: none;
        }
      }
      .MuiFormHelperText-root.Mui-error {
        color: $red-300;
        font: 500 12px/16px ContinentalStagSansW;
        letter-spacing: normal;
        margin-top: 4px;
      }
    }
  }
  .MuiAutocomplete-paper {
    font: 500 16px/21px ContinentalStagSansW;
  }
  &.dark {
    .MuiOutlinedInput-root {
      fieldset {
        border: 1px solid $base-white;
      }
    }
    .MuiFormLabel-root {
      &:not(.Mui-error):not(.Mui-disabled) {
        color: $base-white;

        &.Mui-focused:not(.Mui-error):not(.Mui-disabled) {
          color: $primary1-yellow-500;
        }
      }
      &.Mui-disabled {
        color: $base-white-t30;
      }
    }
    .MuiAutocomplete-input {
      color: $base-white-t50;
      &:focus {
        color: $base-white;
      }
    }
    .MuiAutocomplete-inputRoot {
      &.Mui-focused {
        fieldset {
          border: 1px solid $primary1-yellow-500;
        }
      }
      &.Mui-disabled {
        .MuiOutlinedInput-input {
          -webkit-text-fill-color: initial;
          color: $base-white-t40;
        }
        fieldset {
          border: 1px solid $base-white-t30;
        }
      }
    }
    [aria-owns='combo-box-demo-listbox'] {
      .MuiAutocomplete-inputRoot.Mui-focused fieldset {
        border: 1px solid $base-white;
      }
    }
    .Mui-focused {
      .MuiAutocomplete-input {
        color: $base-white;
      }
    }
    .MuiAutocomplete-paper {
      background: $base-black;
      color: $base-white;
      .MuiAutocomplete-option[aria-selected='true'].Mui-focused,
      .MuiAutocomplete-option.Mui-focused:hover {
        background-color: $base-white-t20;
      }
    }
  }
  &.light {
    .MuiOutlinedInput-root {
      cursor: pointer;
      fieldset {
        border: 1px solid $base-black;
      }
    }
    .MuiFormLabel-root {
      &:not(.Mui-error):not(.Mui-disabled) {
        color: $base-black;

        &.Mui-focused:not(.Mui-error):not(.Mui-disabled) {
          color: $primary1-orange-600;
        }
      }
      &.Mui-disabled {
        color: $base-black-t30;
      }
    }
    .MuiAutocomplete-input {
      color: $base-black-t40;

      &:focus {
        color: $base-black;
      }
    }
    .MuiAutocomplete-inputRoot {
      &.Mui-focused {
        fieldset {
          border: 1px solid $primary1-orange-600;
        }
      }
      &.Mui-disabled {
        .MuiOutlinedInput-input {
          -webkit-text-fill-color: initial;
          color: $base-black-t30;
        }
        fieldset {
          border: 1px solid $base-black-t30;
        }
      }
    }
    .Mui-focused {
      .MuiAutocomplete-input {
        color: $base-black;
      }
    }
    .MuiAutocomplete-paper {
      background: $base-white;
      color: $base-black;
      .MuiAutocomplete-option[aria-selected='true'].Mui-focused,
      .MuiAutocomplete-option.Mui-focused:hover {
        background-color: $base-black-t8;
      }
    }
    .MuiInputBase-adornedEnd {
      margin-left: 0;
      .MuiCircularProgress-root {
        margin-right: 10px;

        .MuiCircularProgress-svg circle {
          color: $base-black-t30;
        }
      }
    }
  }
}

.text-fields {
  .MuiInputBase-root {
    font: 500 16px/21px ContinentalStagSansW;
    letter-spacing: normal;
    border-radius: 8px;
    .MuiInputBase-input {
      height: auto;
    }
    .MuiOutlinedInput-notchedOutline {
      border-width: 1px !important;
    }
  }
  .MuiFormHelperText-root {
    font: 500 12px/16px ContinentalStagSansW;
  }
  &.dark {
    .MuiInputLabel-root {
      color: $base-white-t50;
      font: 500 16px/21px ContinentalStagSansW;
      &.MuiFormLabel-filled,
      &.Mui-focused {
        font-size: 13px;
        line-height: 17px;
        letter-spacing: normal;
      }
    }
    .MuiInputBase-root {
      &:hover:not(.Mui-error):not(.Mui-disabled) {
        .MuiOutlinedInput-notchedOutline {
          border-color: $primary1-yellow-800;
        }
      }
      .MuiOutlinedInput-notchedOutline {
        border-color: $neutrals-gray-700;
      }
      &.Mui-error {
        .MuiOutlinedInput-notchedOutline {
          border-color: $red-300;
        }
      }
      &.Mui-disabled {
        input {
          color: $neutrals-gray-800;
          -webkit-text-fill-color: initial;
        }
        fieldset {
          border-color: $base-white-t10;
        }
      }
    }
    &.MuiTextField-root {
      .MuiInputBase-input {
        color: $base-white-t50;
      }
      fieldset legend span {
        font-size: 10px;
        line-height: 17px;
        letter-spacing: normal;
      }
      .Mui-focused {
        color: $base-white;
        .MuiInputBase-input {
          color: $base-white;
        }
        .MuiOutlinedInput-notchedOutline {
          border: 1px solid $primary1-yellow-500;
        }
        &.Mui-error {
          caret-color: $red-300;
          .MuiOutlinedInput-notchedOutline {
            border: 1px solid $red-300;
          }
        }
      }
    }
    .MuiFormHelperText-root {
      color: $base-white;
    }
    .Mui-error {
      color: $red-300 !important;
    }
    &.outlined-input-icon {
      .MuiInputBase-input {
        color: $base-white-t50;
      }
      .Mui-focused {
        .MuiInputBase-input {
          color: $base-white;
        }
        .MuiOutlinedInput-notchedOutline {
          border-color: $primary1-yellow-500;
        }
        &.Mui-error {
          caret-color: $red-300;
          .MuiOutlinedInput-notchedOutline {
            border-color: $red-300;
          }
        }
      }
    }
  }
  &.light {
    .MuiInputLabel-root {
      color: $base-black-t40;
      font: 500 16px/21px ContinentalStagSansW;
      &.MuiFormLabel-filled,
      &.Mui-focused {
        font-size: 16px;
        line-height: 17px;
        letter-spacing: normal;
      }
    }
    .MuiInputBase-root {
      &:hover:not(.Mui-error):not(.Mui-disabled) {
        .MuiOutlinedInput-notchedOutline {
          border-color: $primary1-yellow-800;
        }
      }
      .MuiOutlinedInput-notchedOutline {
        border-color: $base-black-t30;
      }
      &.Mui-error {
        .MuiOutlinedInput-notchedOutline {
          border-color: $red-300;
        }
      }
      &.Mui-disabled {
        input {
          color: $neutrals-gray-600;
          -webkit-text-fill-color: initial;
        }
        fieldset {
          border-color: $base-black-t10;
        }
      }
      &.Mui-focused {
        caret-color: $primary1-yellow-700;
      }
    }
    &.MuiTextField-root {
      .MuiInputBase-input {
        color: $base-black;
      }
      fieldset legend span {
        font-size: 12px;
        line-height: 17px;
        letter-spacing: normal;
      }
      .Mui-focused {
        color: $base-black;
        .MuiInputBase-input {
          color: $base-black;
        }
        .MuiOutlinedInput-notchedOutline {
          border: 1px solid $primary1-yellow-500;
        }
        &.Mui-error {
          caret-color: $red-300;

          .MuiOutlinedInput-notchedOutline {
            border: 1px solid $red-300;
          }
        }
        fieldset legend span {
          font-size: 12px;
        }
      }
    }
    .MuiFormHelperText-root {
      color: $base-black;
    }
    &.outlined-input-icon {
      .MuiInputBase-input {
        color: $base-black-t40;
      }
      .Mui-focused {
        .MuiInputBase-input {
          color: $base-black;
        }
        .MuiOutlinedInput-notchedOutline {
          border-color: $primary1-yellow-500;
        }
        &.Mui-error {
          caret-color: $red-300;
          .MuiOutlinedInput-notchedOutline {
            border-color: $red-300;
          }
        }
      }
    }
  }
}

.filled-input-field {
  &.dark {
    .MuiInputLabel-root {
      color: $base-white-t40;
    }

    .MuiFilledInput-root {
      background-color: $neutrals-gray-800;
      border-radius: 8px 8px 0 0;
      max-height: 48px;

      &:not(.Mui-disabled):hover {
        background-color: $neutrals-gray-700;
      }

      .MuiFilledInput-input {
        padding-top: 12.5px;
        padding-bottom: 12.5px;
      }

      &.Mui-disabled {
        .MuiFilledInput-input {
          -webkit-text-fill-color: initial;
        }

        &::before {
          border-bottom: 1px solid $base-white-t30;
        }
      }
    }

    .MuiFilledInput-root {
      &:not(.Mui-error):not(.Mui-disabled) {
        &:hover {
          &::before,
          &::after {
            border-bottom: 1px solid $primary1-yellow-500 !important;
          }
        }
        &::before {
          border-bottom: 1px solid $base-white;
        }
        &::after {
          border-bottom: 1px solid $base-white;
        }
        &.MuiFilledInput-underline {
          &:hover {
            &::before,
            &::after {
              border-bottom: 1px solid $primary1-yellow-500;
            }
          }
        }
      }
      &.Mui-error {
        caret-color: $red-300;

        &::after {
          border-bottom-color: $red-300;
        }
      }
    }
    .Mui-focused {
      &.MuiFilledInput-root:not(.Mui-error) {
        &::before,
        &::after {
          border-bottom: 2px solid $base-white;
        }
      }
    }
  }
  &.light {
    .MuiInputLabel-root {
      color: $base-black-t40;
    }
    .MuiFilledInput-root {
      background-color: $neutrals-gray-50;
      border-radius: 8px 8px 0 0;
      max-height: 48px;
      &:not(.Mui-disabled):hover {
        background-color: $neutrals-gray-100;
      }
      .MuiFilledInput-input {
        padding-top: 12.5px;
        padding-bottom: 12.5px;
      }
      &.Mui-disabled {
        .MuiFilledInput-input {
          -webkit-text-fill-color: initial;
        }
        &::before {
          border-bottom: 1px solid $base-black-t30;
        }
      }
    }
    .MuiFilledInput-root {
      &:not(.Mui-error):not(.Mui-disabled) {
        &:hover {
          &::before,
          &::after {
            border-bottom: 1px solid $primary1-yellow-500 !important;
          }
        }
        &::before {
          border-bottom: 1px solid $base-black;
        }
        &::after {
          border-bottom: 1px solid $base-black;
        }
        &.MuiFilledInput-underline {
          &:hover {
            &::before,
            &::after {
              border-bottom: 1px solid $primary1-yellow-500;
            }
          }
        }
      }
      &.Mui-error {
        caret-color: $red-300;

        &::after {
          border-bottom-color: $red-300;
        }
      }
    }
    .Mui-focused {
      &.MuiFilledInput-root:not(.Mui-error) {
        &::before,
        &::after {
          border-bottom: 2px solid $base-black;
        }
      }
    }
  }
}

.tabbed-navigation {
  .MuiTabs-root {
    border-radius: 8px;
    background-color: $base-black-t10;
    width: max-content;
    min-height: initial;
    position: relative;
    z-index: 1;
    .MuiButtonBase-root {
      // font: 700 10px/13px ContinentalStagSansW;
      font: 500 16px/16px ContinentalStagSansW-M;
      // letter-spacing: 0.115em;
      letter-spacing: 0.02em;
      color: $base-black;
      min-width: initial;
      min-height: initial;
      // padding: 8px 16px 6px;
      padding: 8px 24px;
      text-transform: none;
      &:hover {
        background-color: $base-black-t5;
        border-radius: 8px;
      }
      &.Mui-selected {
        color: $base-white;
        background-color: $base-black;
        border-radius: 8px;
      }
    }
    .MuiTabs-indicator {
      display: none;
    }
  }
}

.nav-item-wrapper {
  background-color: $base-black-t40;
  color: $base-white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 96px;
  width: 96px;
  border-radius: 8px;
  &:hover {
    background-color: $base-white-t10;
  }
  &:active,
  &.active {
    background: rgba(254, 165, 0, 0.25);
  }
  .nav-icon {
    margin-bottom: 7px;
  }
}

.custom-tag {
  padding: 8px;
  border-radius: 4px;
  max-width: fit-content;
  font: 400 13px/18px ContinentalStagSansW;
  letter-spacing: 0.01em;
  color: $neutrals-gray-700;
}

.search-field {
  &.MuiPaper-root {
    box-shadow: none;
    border-radius: 8px;
    padding: 5px;
    background: transparent;
    &.dark {
      border: 1px solid $base-black-t30;
      .MuiInputBase-root {
        color: $neutrals-gray-700;
        font: 500 16px/21px ContinentalStagSansW;
        caret-color: $primary1-yellow-700;
      }
      &:hover {
        border-color: $primary1-yellow-800;
      }
      &:focus,
      &:focus-within,
      &:focus-visible {
        border-color: $primary1-yellow-700;
      }
      &:focus-within {
        .MuiInputBase-root {
          color: $base-black;
        }
        .no-search {
          svg {
            fill: $base-black;
          }
        }
      }
      .no-search {
        svg {
          fill: $neutrals-gray-700;
        }
      }
    }
    &.light {
      border: 1px solid $base-white-t30;
      .MuiInputBase-root {
        color: $neutrals-gray-600;
        font: 500 16px/21px ContinentalStagSansW;
        caret-color: $primary1-yellow-500;
      }
      &:hover {
        border-color: $primary1-yellow-800;
      }
      &:focus,
      &:focus-within,
      &:focus-visible {
        border-color: $primary1-yellow-500;
      }
      &:focus-within {
        .MuiInputBase-root {
          color: $base-white;
        }
        .no-search {
          svg {
            fill: $base-white;
          }
        }
      }
      .no-search {
        svg {
          fill: $neutrals-gray-600;
        }
      }
    }
  }
}

.search-chip {
  position: relative;
  .MuiInputBase-root.MuiOutlinedInput-root {
    border-radius: 8px;
  }
  .MuiInputBase-adornedEnd {
    padding-right: 20px;
  }
  .MuiChip-root {
    max-width: 200px;
    font: 400 13px ContinentalStagSansW;
    border-radius: 4px;
    margin-right: 5px;
    margin-bottom: 5px;
    letter-spacing: 0.01em;
    padding: 0 8px;
    &:last-child {
      margin-right: 0;
    }
    svg {
      height: 9.5px;
      width: 9.5px;
      &:hover,
      &:active {
        fill: $primary1-yellow-800;
      }
    }
    .MuiChip-deleteIcon {
      margin-right: 0;
      margin-left: 7px;
      padding: 0;
      &:hover,
      &:active {
        background: none;
      }
      .MuiTouchRipple-root {
        display: none;
      }
    }
    .MuiChip-label {
      padding: 0;
    }
  }
  &.dark {
    .MuiInputBase-root {
      @include p-large-medium;
      color: $base-white;
      margin-bottom: 5px;
      fieldset {
        border: 1px solid $base-white-t20;
        border-radius: 8px;
      }
      &.Mui-focused {
        fieldset {
          border: 1px solid $base-white-t20;
        }
      }
      &:hover,
      &:active {
        fieldset {
          border: 1px solid $base-white-t20;
        }
      }
    }
    .MuiChip-root {
      color: $primary1-yellow-700;
      background-color: $base-white-t10;
      svg {
        fill: $primary1-yellow-700;
        &:hover,
        &:active {
          fill: $primary1-yellow-800;
        }
      }
    }
    .MuiOutlinedInput-root:not(.Mui-disabled):not(.Mui-error) {
      fieldset {
        border: 1px solid $base-white-t30;
      }
      &:hover fieldset {
        border: 1px solid $primary1-yellow-800;
      }
      &.Mui-focused {
        fieldset {
          border: 1px solid $primary1-yellow-500;
        }
        caret-color: $primary1-yellow-500;
      }
    }
  }
  &.light {
    .MuiInputBase-root {
      @include p-large-medium;
      color: $neutrals-gray-700;
      margin-bottom: 5px;
      fieldset {
        border: 1px solid $base-black-t30;
        border-radius: 8px;
      }
      &.Mui-focused {
        fieldset {
          border: 1px solid $base-black-t30;
        }
      }
      &:hover,
      &:active {
        fieldset {
          border: 1px solid $base-black-t30;
        }
      }
    }
    .MuiChip-root {
      color: $primary1-yellow-700;
      background-color: $base-black-t5;
    }
    .MuiOutlinedInput-root:not(.Mui-disabled):not(.Mui-error) {
      &:hover fieldset {
        border: 1px solid $primary1-yellow-800;
      }
      &.Mui-focused {
        fieldset {
          border: 1px solid $primary1-yellow-700;
        }
        caret-color: $primary1-yellow-700;
      }
    }
  }
}

.autoComplete-wrapper,
.multiselect-dropdown-wrapper {
  &.dark {
    .MuiPaper-rounded {
      background: $neutrals-gray-900;
      margin-top: 4px;
      margin-bottom: 4px;
      border-radius: 8px;
      box-shadow: 0 7px 24px rgba(0, 0, 0, 0.45);
      transform: translateZ(0);
    }
    .MuiAutocomplete-popperDisablePortal {
      .MuiAutocomplete-noOptions {
        color: $base-white;
        background: $neutrals-gray-900;
      }
    }
    .MuiAutocomplete-listbox,
    .custom-dark-autocomp-popup {
      background: $neutrals-gray-900;
      color: $base-white;
      &::-webkit-scrollbar {
        width: 12px;
      }
      &::-webkit-scrollbar-track {
        background: transparent;
        border-radius: 10px;
      }
      &::-webkit-scrollbar-thumb {
        background: $base-black-t40;
        border-radius: 100px;
        background-clip: padding-box;
        border: 4px solid transparent;
      }
      .MuiAutocomplete-option[aria-selected='true'] {
        color: $primary1-yellow-500;
        background: transparent;
      }
    }
  }
  &.light {
    .MuiPaper-rounded {
      background: $base-white;
      margin-top: 4px;
      margin-bottom: 4px;
      border-radius: 8px;
      box-shadow: 0 7px 24px rgba(0, 0, 0, 0.15);
      transform: translateZ(0);
    }
    .MuiAutocomplete-option {
      background: none !important;
      &[aria-selected='true'] {
        color: $base-black;
      }
      &:hover,
      &.Mui-focused {
        color: $primary1-yellow-700;
      }
    }
    .MuiAutocomplete-popperDisablePortal {
      .MuiAutocomplete-noOptions {
        color: $base-black;
        background: $base-white;
      }
    }
    .MuiAutocomplete-listbox,
    .custom-light-autocomp-popup {
      background: $base-white;
      color: $base-black;
      &::-webkit-scrollbar {
        width: 12px;
      }
      &::-webkit-scrollbar-track {
        background: transparent;
        border-radius: 10px;
      }
      &::-webkit-scrollbar-thumb {
        background: $neutrals-gray-200;
        border-radius: 100px;
        background-clip: padding-box;
        border: 4px solid transparent;
      }
    }
  }
  .MuiAutocomplete-listbox {
    .MuiAutocomplete-option {
      padding-top: 17px;
      padding-bottom: 17px;
    }
  }
}

.autocomplete-suggestion {
  .autoComplete {
    .MuiOutlinedInput-root,
    .MuiInputBase-input {
      cursor: text;
    }
  }
}

.multiselect-dropdown-wrapper {
  &.light {
    .MuiAutocomplete-option {
      &[aria-selected='true'] {
        color: $primary1-yellow-700;
      }
    }
  }
}

.multiselect-dropdown,
.autoComplete {
  .MuiTextField-root {
    .MuiInputBase-root {
      border-radius: 8px;
      .MuiAutocomplete-endAdornment {
        right: 22px;
        top: 50%;
        transform: translateY(-50%);

        button {
          &:hover,
          &:active,
          &:focus {
            background: none;
          }
        }
      }
    }
  }
  &.dark {
    .MuiInputBase-root {
      color: $base-white;
      fieldset {
        border-color: $base-white-t30;
      }
      &:hover {
        fieldset {
          border-color: $primary1-yellow-800;
        }
      }
      &.Mui-focused {
        fieldset {
          border: 1px solid $primary1-yellow-500;
        }
        .MuiIconButton-root {
          svg path {
            fill: $primary1-yellow-500;
          }
        }
      }
      .MuiInputBase-input {
        color: $base-white;
        caret-color: $primary1-yellow-500;
      }
    }
    .selection-count {
      background-color: $base-white-t10;
    }
    .MuiIconButton-root {
      svg path {
        fill: $neutrals-gray-600;
      }
    }
  }
  &.light {
    .MuiInputBase-root {
      color: $base-black;
      fieldset {
        border-color: $base-black-t30;
      }
      &:hover {
        fieldset {
          border-color: $primary1-yellow-800;
        }
      }
      &.Mui-focused {
        fieldset {
          border-width: 1px;
          border-color: $primary1-yellow-700;
          //border: 1ps solid $primary1-yellow-700 !important;
        }
        .MuiIconButton-root {
          svg path {
            fill: $primary1-yellow-700;
          }
        }
      }
      .MuiInputBase-input {
        color: $base-black;
        caret-color: $primary1-yellow-700;
      }
      p {
        padding: 7.5px 4px 7.5px 6px;
        font-size: 16px;
        line-height: 21px;
        color: rgba(0, 0, 0, 0.42);
      }
    }
    .selection-count {
      background-color: $base-black-t5;
    }
    .MuiIconButton-root {
      svg path {
        fill: $neutrals-gray-700;
      }
    }
  }
  .selection-count {
    height: 32px;
    min-width: 32px;
    width: max-content;
    color: $primary1-yellow-700;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    font-size: 12px;
    line-height: 12px;
    font-weight: 400;
  }
}

.autocomplete-suggestion {
  .MuiAutocomplete-inputRoot {
    padding-right: 6.5px !important;
    .MuiAutocomplete-endAdornment {
      display: none;
    }
  }
}

.custom-chip {
  &.MuiChip-root {
    border-radius: 4px;
    font: 400 13px/18px ContinentalStagSansW;
    letter-spacing: 0.01em;
    .MuiChip-label {
      padding-left: 8px;
      padding-right: 6px;
    }
    .MuiChip-deleteIcon {
      padding: 0;
      margin: 0 8px 0 0;
    }
  }
}

.popup-custom {
  padding: 3px 0;
  &.Mui-focused {
    color: $primary1-yellow-500;
  }
  .checkboxLabel {
    margin-left: 16px;
    display: block;
    .MuiFormControlLabel-label {
      span {
        color: red;
      }
    }
  }
}

.datePickerContainer {
  width: 100%;
  .MuiFormControl-root {
    width: 100%;
    .MuiInputBase-root {
      &:hover {
        fieldset {
          border: 1px solid $primary1-yellow-800;
        }
      }
      &.Mui-focused {
        fieldset {
          border: 1px solid $primary1-yellow-500;
        }
      }
      input {
        color: $neutrals-gray-600;
      }
      fieldset {
        border: 1px solid $base-white-t30;
        border-radius: 8px;
      }
    }
  }
  &.light {
    .MuiFormControl-root {
      .MuiInputBase-root {
        &:hover {
          fieldset {
            border: 1px solid $primary1-yellow-800;
          }
        }
        input {
          color: $base-black;
        }
        fieldset {
          border: 1px solid $base-black-t30;
        }
      }
    }
  }
}
.MuiDialog-root.MuiModal-root {
  z-index: 10000;
}
.MuiDialog-paper {
  .MuiDialogContent-root {
    .MuiCalendarPicker-root {
      .PrivatePickersSlideTransition-root {
        button {
          &:hover {
            background-color: rgba(255, 165, 0, 0.1);
          }
        }
        .Mui-selected {
          background-color: $primary1-yellow-500;
        }
      }
    }
  }
  .MuiDialogActions-root {
    button {
      color: $primary1-yellow-500;
      &:hover {
        background-color: rgba(255, 165, 0, 0.1);
      }
    }
  }
}
// Only component specific scss

.tabbed-navigation .MuiTabs-root.pdf-header_files {
  border-radius: 0;
  background-color: transparent;
  width: 100%;
  [role='tablist'] {
    flex-wrap: wrap;
    gap: 5px;

    .MuiButtonBase-root.MuiTab-root {
      max-width: calc(25% - 4px);
      word-break: break-all;
      // background-color: rgba(0, 0, 0, 0.1);
    }
  }
}

.tabbed-navigation-type-topics {
  .MuiTabs-root {
    background-color: transparent;
    width: auto;
    .MuiTabs-scroller.MuiTabs-fixed {
      .MuiTabs-flexContainer {
        gap: 4px;
        background-color: transparent;
        flex-wrap: wrap;
        button {
          background-color: rgba(0, 0, 0, 0.1);
          border-radius: 8px;
          font-weight: normal;
          &:hover {
            background-color: rgba(0, 0, 0, 0.25);
          }
          &.MuiButtonBase-root.Mui-selected {
            background-color: #000;
          }
        }
      }
    }
  }
}
