/** @format */

.admin-user {
  .user-list {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 8px;
    margin-bottom: 50px;

    .heading-column,
    .list-item {
      display: grid;
      grid-template-columns: 1fr;
      font-size: 14px;
      font-family: inherit;
      line-height: 29px;

      @media (min-width: 768px) {
        grid-template-columns: 2fr 1fr 1fr 1.5fr 1fr;
        font-size: 16px;
        grid-column-gap: 6px;
        .action {
          justify-self: center;
        }

        .country,
        .company,
        .register-date {
          display: none;
        }
      }

      @media (min-width: 1280px) {
        grid-template-columns: 2fr 1fr 1fr 1fr 1fr 1.5fr 0.5fr 0.5fr;
        align-items: center;

        .admin-user-nav-open & {
          grid-template-columns: 1fr 1fr 1fr 0.5fr 0.5fr;
        }

        .country,
        .company,
        .register-date {
          display: block;
          /* text-align: center; */
        }
      }
    }

    .admin-user-no-result {
      font-size: 1.6rem;
      padding: 24px;
    }

    .heading-column {
      text-transform: capitalize;
      font-weight: 600;
      color: $neutrals-gray-500;
      padding: 0px 24px;
      display: none;

      @media (min-width: 768px) {
        display: grid;
        position: sticky;
        top: 0;
        background-color: #faf4eb;
        z-index: 10;
        padding-bottom: 10px;
      }
    }

    .list-item {
      padding: 24px 24px 21px;
      background-color: #ffffff;
      border-radius: 8px;
      .action {
        .close {
          svg {
            margin-left: 3px;
          }
        }
        button {
          background-color: transparent;
          margin-top: unset;
          margin-bottom: unset;
          &:not(:last-child) {
            margin-right: 10px;
          }
        }
        @media (min-width: 768px) {
          justify-self: center;
        }
      }
      & > * {
        display: flex;
        justify-content: space-between;
        word-break: break-all;

        @media (min-width: 768px) {
          text-overflow: ellipsis;
          height: 3.2rem;
          overflow: hidden;
          span {
            &:not(.buttons) {
              width: 100%;
            }
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
          button {
            width: 32px;
          }
        }

        label {
          text-transform: capitalize;
          font-weight: 600;
          color: $neutrals-gray-600;
          @media (min-width: 768px) {
            display: none;
          }
        }
      }
    }

    &.extranet-user-list {
      .heading-column,
      .list-item {
        @media (min-width: 768px) {
          // grid-template-columns: 2fr 1fr 1fr 1.5fr 1fr;
        }

        @media (min-width: 1280px) {
          grid-template-columns: 2fr 1fr 1fr 1fr 1fr 1fr 0.5fr;
        }
      }
    }
  }
}

.user-detail_wrapper {
  background: $base-white;
  padding: 1.5rem 3rem;
  margin-top: -1rem;
  border-radius: 0 0 10px 10px;
  & > div {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    font-family: inherit;
    font-size: 18px;
    line-height: 29px;
    &:not(:last-child) {
      margin-bottom: 20px;
    }
    label {
      color: $neutrals-gray-600;
    }
    @media (max-width: 575px) {
      font-size: 14px;
    }
  }
}

.sort-label {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  cursor: pointer;
  &.sort-label-active {
    color: #000;
  }
}
.locale .sort-label,
.role .sort-label,
.f-name .sort-label,
.email .sort-label {
  justify-content: flex-start;
}

.user-editor {
  background: $base-white;
  padding: 38px;
  margin-bottom: 1rem;
  border-radius: 0 0 10px 10px;
  font-size: 18px;

  .user-editor-columns {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 76px;
    padding-right: 38px;
  }
  .user-editor-col-title {
    font-weight: bold;
    margin-bottom: 34px;
    font-size: 18px;
  }
  .user-editor-col {
    .user-editor-gray-box {
      background-color: #fafafa;
      border-radius: 20px;
      padding: 20px 40px 50px;
      margin-left: -40px;
      margin-right: -40px;
    }
    .attribute {
      display: flex;
      align-items: center;
      &:not(:last-child) {
        margin-bottom: 38px;
      }
      &.attribute-less-margin-bottom {
        margin-bottom: 24px;
      }
      label {
        min-width: 200px;
      }
      & > span,
      & > div {
        flex: auto;
      }
    }
  }
}
.delete-dialog {
  .MuiDialog-paper {
    max-width: none;
    border-radius: 20px;
    .MuiDialogContent-root {
      padding: 0;
    }
  }
  .delete-user-dialog {
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    padding: 130px 180px;
    .delete-confirm {
      margin-bottom: 40px;
    }
    .delete-confirm-name {
      margin-bottom: 70px;
    }
    .delete-confirm-desc {
      font-size: 18px;
    }
    .delete-confirm-btns {
      margin-top: 94px;
      justify-content: center;
    }
  }
}

.admin-lists {
  margin-top: 30px;
  display: flex;
  gap: 24px;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  @media (min-width: 1280px) {
    flex-direction: row;
  }
  .admin-item {
    background-color: #f4e7d4;
    color: black;
    text-decoration: none;
    padding: 24px;
    height: 374px;
    flex: 1;
    width: 100%;
    border-radius: 24px;
    position: relative;
    @media (min-width: 1280px) {
      width: auto;
    }
    &:hover {
      opacity: 0.8;
    }
    &-title {
      font-size: 28px;
      line-height: 36px;
      font-weight: bold;
    }
    &-update-time {
      margin-top: 8px;
      font-size: 13px;
      line-height: 18px;
    }
    &-btn {
      position: absolute;
      bottom: 50%;
      right: 24px;
      box-shadow: none;
      min-width: 50px;
      width: 50px;
      height: 50px;
      border-radius: 50px;
      background-color: black;
      padding: 0;
      margin: 0;
      transform: translateY(50%);
      @media (min-width: 1280px) {
        bottom: 24px;
        transform: none;
      }
      &:hover {
        background-color: #ffa500;
        box-shadow: none;
      }
      > span {
        margin: 0;
      }
    }
  }
}

.multiselect-types {
  & > div > div.MuiInputBase-formControl {
    flex-wrap: nowrap;
    .MuiAutocomplete-endAdornment {
      background-color: #faf4eb;
      right: 0 !important;
      padding-right: 16px;
    }
  }
}
