/** @format */

.topics-content-wrapper {
  padding: 40px 0 24px;
  h4 {
    font-size: 24px;
    line-height: 30px;
    cursor: pointer;
  }
  .topic-date {
    font-size: 13px;
    line-height: 18px;
    letter-spacing: 0.01em;
    color: $base-black-t40;
    margin-left: 20px;
    min-width: max-content;
  }
  & + .topics-content-wrapper {
    border-top: 1px solid $base-black-t25;
  }
}

.topics-card-wrapper {
  // background-color: rgba(255, 255, 255, 0.16);
  background-color: $base-white-t15;
  border-radius: 8px;
  padding: 18px 26px 18px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  width: 100%;
  margin-bottom: 8px;
  &:last-child {
    margin-bottom: 0;
  }
  .card-topic {
    font: 500 16px/18px ContinentalStagSansW-M;
    letter-spacing: 0.01em;
    color: $base-white;
  }
  &:hover,
  &.active {
    background-color: $primary1-yellow-500;
    .card-topic {
      color: $base-black;
    }
    svg {
      fill: $base-black;
      path {
        stroke: $base-black;
        // fill: $base-black;
      }
    }
  }

  // @media screen and (max-width: 899px) {
  margin-bottom: 0;

  &:not(.profile-nav-button) {
    svg {
      transform: rotate(90deg);
    }

    &.active {
      &:has(> div) {
        border-radius: 8px 8px 0 0;
      }
      position: sticky;
      top: 0;
      z-index: 11;
      @media screen and (max-width: 899px) {
        top: 20px;
      }

      svg {
        transform: rotate(270deg);
      }
    }
  }

  &.profile-nav-button:not(.logout) {
    svg {
      display: none;
    }
  }
  // }
}

.base_topic_srollable {
  overflow: hidden;
  overflow-y: scroll;
  /* margin-top: -20px; */
  /* padding-top: 20px; */
  @media (min-width: 1024px) {
    padding-right: 40px;
    &::-webkit-scrollbar {
      background: black;
      width: 8px;
      height: 0;
    }
    &::-webkit-scrollbar-thumb {
      background: black;
    }
    &::-webkit-scrollbar-track {
      background: #1f1f1f;
    }
  }

  &.fixedTab {
    padding-top: 59px;
    @media (max-width: 899px) {
      padding-top: 40px;
    }
    .sticky-tab-content {
      position: absolute;
      top: 80px;
      left: 56px;
      width: calc(100% - 269px);
      @media (max-width: 1400px) {
        width: calc(100% - 160px);
      }
      @media (max-width: 1024px) {
        width: calc(100% - 112px);
      }
      @media (max-width: 899px) {
        width: calc(100% - 80px);
        top: 40px;
        left: 40px;
      }
      @media (max-width: 767px) {
        width: calc(100% - 32px);
        top: 40px;
        left: 16px;
      }
    }
  }
}

.base_topic_wrapper {
  @media screen and (max-width: 899px) {
    width: calc(200% + 16px) !important;
    padding-right: calc(100% + 16px) !important;
  }
}

button.back_btn {
  font-family: 'ContinentalStagSansW';
  padding: 0;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.12em;
  min-width: 0;
  font-size: 21px;
  color: #ffffff;
}

.base_topic_list {
  position: relative;
  // @media screen and (max-width: 1819px) {
  //   padding-right: 12px;
  // }
  // @media screen and (max-width: 899px) {
  padding-right: 0;
  // }
  // @media screen and (min-width: 1820px) {
  //   max-width: 300px !important;
  // }
  .topic_wrapper {
    margin-bottom: 8px;
    border-radius: 8px;
    &-favorite {
      button {
        background-repeat: no-repeat;
        background-position: right 24px center;
        background-image: url(../../assets/icons/tag_orange.png);
        background-size: 24px 24px;
        &:hover,
        &.active {
          background-image: url(../../assets/icons/tag_white.png);
          border-radius: 8px;
        }
      }
    }
    @media screen and (max-width: 899px) {
      // position: relative;
    }
    .sub_menu {
      // background: $neutrals-gray-900;
      position: absolute;
      top: 0;
      bottom: 0;
      left: auto;
      right: calc(-100% - 24px);
      width: 100%;
      display: flex;
      flex-direction: column;
      z-index: 1;
      // @media screen and (max-width: 1819px) {
      //   right: -100%;
      //   padding-left: 12px;
      // }
      // .sub_menu_sticky {
      //   position: sticky;
      //   top: 0;
      // }
      .sub-menu-inner {
        margin-bottom: 8px;
        // @media screen and (max-width: 899px) {
        margin-bottom: 0;
        &:last-child {
          .topics-card-wrapper {
            border-radius: 0 0 8px 8px;
          }
        }
        // }
      }
      .topics-card-wrapper {
        svg {
          fill: none;
          // @media screen and (max-width: 899px) {
          transform: rotate(0deg);
          // }
        }
        &.active {
          border-radius: 8px;
          margin-bottom: 0;
          // @media screen and (max-width: 899px) {
          background-color: rgba(255, 255, 255, 0.08);
          border-radius: 0 !important;
          .card-topic {
            color: $primary1-yellow-500;
          }
          // }
          svg {
            &:not(.btn_favorite_svg) {
              transform: rotate(180deg);
            }
            path {
              stroke: $base-black;
            }
            // @media screen and (max-width: 899px) {
            // display: none;
            path {
              stroke: $primary1-yellow-500;
            }
            // }
          }
          &.active-sub {
            border-radius: 8px 8px 0 0;
          }
        }
        // @media screen and (max-width: 899px) {
        border-radius: 0;
        // }
      }
      // &.bottom-0 {
      //     top: 0;
      //     bottom: 0px;
      // }
      // &.bottom-65 {
      //     top: 0;
      //     bottom: -62px;
      // }
      .sub_item {
        // margin-top: -8px;
        // background: rgba(255, 255, 255, 0.1);
        background: rgba(255, 255, 255, 0.08);
        list-style: none;
        font-size: 16px;
        border-radius: 0 0 10px 10px;
        // @media screen and (max-width: 899px) {
        margin-bottom: 8px;
        padding-bottom: 16px;
        // }
        li {
          font: 500 16px/18px ContinentalStagSansW-M;
          letter-spacing: 0.01em;
          padding: 25px 26px 25px 25px;
          cursor: pointer;
          display: flex;
          justify-content: space-between;
          &.active,
          &:hover {
            color: $primary1-yellow-500;
          }
          // @media screen and (max-width: 899px) {
          padding: 11px 25px 11px 22px;
          margin-left: 24px;
          border-left: 2px solid white;
          // }
        }
      }
      // @media screen and (max-width: 899px) {
      position: relative;
      right: 0;
      width: 100%;
      padding-left: 0;
      .active ~ .sub_item {
        border-radius: 0;
        margin-bottom: 0;
      }
      // }
    }
  }
  .btn_favorite {
    background-color: transparent;
    width: 21px;
    height: 21px;
    position: relative;
    right: -7px;
    &:hover {
      path:first-child {
        fill: white;
      }
    }
    &_remove {
      path:first-child {
        fill: white;
      }
      .btn_favorite_remove_minus {
        stroke: black;
      }
      &:hover {
        path:first-child {
          fill: none;
          stroke: black;
        }
        .btn_favorite_remove_minus {
          stroke: black;
        }
      }
    }
  }
  .sub_item {
    .btn_favorite {
      &:hover {
        path:first-child {
          fill: orange;
          stroke: orange;
        }
      }
    }
  }
}
h4.base_topic_heading {
  font-family: 'ContinentalStagSansW';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.07em;
  /* text-transform: uppercase; */
  color: rgba(255, 255, 255, 1);
  margin-bottom: 27px;
}
.base_topic_favorite {
  margin-bottom: 40px;
  @media screen and (max-width: 899px) {
    margin-bottom: 24px;
  }
}

.topic_last_search {
  border-bottom: 1px solid $neutrals-gray-500;
  font-size: 1.2rem;
  color: $neutrals-gray-500;
  padding: 20px 0;
}

.topics-content {
  font-size: 18px;
  line-height: 29px;
  color: $neutrals-gray-700;
  margin-top: 16px;
  max-width: 700px;
  p {
    color: $neutrals-gray-700;
  }
  @media screen and (max-width: 899px) {
    font-size: 17px;
    line-height: 25px;
    margin-top: 8px;
  }
}

.documents-wrapper {
  font-size: 13px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 32px 0;
  color: black;
  .document {
    display: flex;
    align-items: center;
    gap: 32px;
    padding-bottom: 24px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.25);
  }
  .type {
    background-color: rgba(0, 0, 0, 0.1);
    height: 27px;
    line-height: 27px;
    min-width: 56px;
    text-align: center;
    border-radius: 4px;
    text-transform: uppercase;
  }
  .size {
    text-align: center;
  }
  .title {
    flex: auto;
  }
  button {
    background-color: transparent;
  }
  @media screen and (max-width: 899px) {
    .document {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: repeat(2, 1fr);
      grid-column-gap: 0px;
      grid-row-gap: 8px;
    }
    .type {
      grid-area: 1 / 1 / 2 / 2;
    }
    .size {
      grid-area: 1 / 2 / 2 / 3;
    }
    .title {
      display: inline-block;
      grid-area: 2 / 1 / 3 / 5;
    }
    button {
      display: inline-block;
    }
    .view-btn {
      grid-area: 1 / 3 / 2 / 4;
    }
    .download-btn {
      grid-area: 1 / 4 / 2 / 5;
    }
  }
}

.pdf-viewer-wrapper {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  .close-drawer {
    position: absolute;
    right: 40px;
    top: 40px;
    background-color: transparent;
    font-size: 18px;
    display: flex;
    gap: 16px;
    svg {
      width: 16px;
      height: 16px;
    }
  }
}
.pdf-viewer-container {
  max-width: 1350px;
  width: 100%;
  margin: 0 auto;
  .pdf-viewer-top {
    font-size: 14px;
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 40px;
    .type-size {
      display: flex;
    }
    .type {
      text-transform: uppercase;
      border-right: 1px solid #000;
      padding-right: 8px;
      margin-right: 8px;
    }
    .size {
    }
    .title {
      flex: auto;
    }
    .download-btn {
      background-color: #000;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 16px;
      border-radius: 100px;
      height: 50px;
      padding: 0 24px;
      font-size: 16px;
    }
  }
  .react-pdf__Document {
    position: relative;
    .skeleton {
      font-size: 14px;
      min-height: 600px;
      background-color: #ddd;
      border-radius: 16px;
      padding: 16px;
    }
  }
  .slide1 {
    .slick-slide {
      opacity: 0.5;
      &.slick-current {
        opacity: 1;
      }
      &.slick-current {
      }
    }
    .onboard-image {
      padding-right: 24px;
      .react-pdf__Page {
        border-radius: 16px;
        overflow: hidden;
      }
    }
  }
  .preview-pdf-pagi {
    margin-top: 16px;
    width: calc(100% - 156px);
    box-sizing: border-box;
    * {
      box-sizing: border-box;
    }
    /* margin-right: 156px; */
    .slick-track {
      min-width: 100%;
    }
    .onboard-image {
      padding-right: 8px;
      .react-pdf__Page {
        border-radius: 8px;
      }
    }
    .slick-slide {
      opacity: 1;
      &.slick-current {
        .react-pdf__Page {
          border: 1px solid #ffa500;
        }
      }
    }
  }
  .custom-arrow {
    position: absolute;
    bottom: 0;
    right: 0;
    height: 100px;
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: flex-end;
    .customButton {
      margin: 0;
    }
  }
}

.topic_brand_lists {
  display: flex;
  margin: 0 auto 24px;
  li {
    background-color: #000;
    list-style: none;
    &:first-child {
      border-radius: 8px 0 0 8px;
    }
    &:last-child {
      border-radius: 0 8px 8px 0;
    }
    button {
      font-size: 13px;
      background-color: transparent;
      padding: 11px 16px;
      color: #fff;
      &:hover:not(.active) {
        opacity: 0.5;
      }
      &.active {
        border-radius: 8px;
        background-color: #ffa500;
        color: #000;
      }
    }
  }
}

.top_heading {
  background-color: #faf4eb;
  position: sticky;
  top: -50px;
  margin-top: -50px;
  padding-top: 50px;
  padding-bottom: 40px;
  margin-bottom: -40px;
  z-index: 2;

  @media screen and (max-width: 899px) {
    padding-top: 1.6rem;
    padding-bottom: 1.6rem;
    margin-bottom: -1.6rem;
  }
}

[role='tooltip'] {
  z-index: 9999 !important;
  .MuiTooltip-tooltip {
    padding: 16px !important;
    background-color: black;
    box-shadow: 0 7px 24px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
  }
  .MuiTooltip-arrow {
    color: black;
  }
}

.sticky-tab-content {
  /* position: sticky;
  top: -20px; */
  background: #1f1f1f;
  z-index: 12;
}

.scroll-handle-element {
  height: 100%;
  overflow: hidden;
  overflow-y: scroll;
  .page_header h1 {
    transition: ease all 0.3s;
  }
  .breadcrumb {
    transition: 0.3s ease all;
    overflow: hidden;
    transform-origin: left top;
    height: 30px;
    &:empty {
      height: 0;
    }
  }
  &.is-scrolled {
    .page_header h1 {
      font-size: 28px;
      line-height: 36px;
    }
    .breadcrumb {
      transform: scale(0);
      height: 0;
      margin-bottom: 0;
    }
  }
}
