@mixin transition($taget, $time, $fn) {
  -webkit-transition: $taget $time $fn;
  -moz-transition: $taget $time $fn;
  -o-transition: $taget $time $fn;
  -ms-transition: $taget $time $fn;
  transition: $taget $time $fn;
}

@mixin middle-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
// .border-radius-4 {
//   border-radius: 4px;
// }

// @mixin border-radius-8 {
//   border-radius: 8px;
// }

// .border-radius-8 {
//   border-radius: 8px;
// }

// .border-radius-16 {
//   border-radius: 16px;
// }

// .border-radius-24 {
//   border-radius: 24px;
// }

// .border-radius-40 {
//   border-radius: 40px;
// }

.elevation-1 {
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.12), 0px 5px 13px rgba(0, 0, 0, 0.1);
}

@mixin elevation-1 {
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.12), 0px 5px 13px rgba(0, 0, 0, 0.1);
}

.elevation-2 {
  box-shadow: 0px 4px 64px rgba(0, 0, 0, 0.5);
}

@mixin elevation-1 {
  box-shadow: 0px 4px 64px rgba(0, 0, 0, 0.5);
}

// responsive
// @mixin media($repons) {
//   // phone
//   @if ($repons == sx) {
//     @media screen and (min-width: 375px) {
//       @content;
//     }
//   }
//   // tablet portrait
//   @if ($repons == md) {
//     @media screen and (min-width: 768px) {
//       @content;
//     }
//   }
//   // tablet landscape
//   @if ($repons == xl) {
//     @media screen and (min-width: 992px) {
//       @content;
//     }
//   }
//   // normal desktop
//   @if ($repons == lg) {
//     @media screen and (min-width: 1160px) {
//       @content;
//     }
//   }
//   @if ($repons == xlg) {
//     @media screen and (min-width: 1400px) {
//       @content;
//     }
//   }
//   @if ($repons == xxl) {
//     @media screen and (min-width: 1800px) {
//       @content;
//     }
//   }
// }

@mixin flex {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
@mixin center {
  margin-left: auto;
  margin-right: auto;
}
