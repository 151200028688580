/** @format */

.vdo-login {
  background-color: #fff;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  flex-direction: column;
  gap: 1rem;
}

.vdo-login-error {
  color: red;
  font-size: 1.6rem;
}
