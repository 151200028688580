.pdf-wrapper {

    margin-top: 80px !important;

    @media(min-width:1600px) {
        max-width: 1300px !important;
    }

    .pdf-header {

        display: flex;
        align-items: center;
        
        overflow-x: scroll;
        &>* {
            margin: 5px;
        }
        .pdf-header_item{
            white-space: nowrap;
        }
        @media(min-width:1024px){
            margin: -20px;
            &>* {
                margin: 20px;
            }
            &>.pdf-header_item:first-child {
                flex: 1;
            }
        }
        
    }

    .pdf-content {
        margin-top: 24px;
        width: 100%;

        .react-pdf__Document {
            position: relative;
            .custom-arrow{
                display: none;
            }
            @media(min-width:768px){
                .custom-arrow {
                    display: block;
                    position: absolute;
                    bottom: 16px;
                    right: 0px;
                    // margin: -5px;
    
                    &>button {
                     &:not(:last-child){
                        margin-right:10px;
                     }
                    }
                }
            }
           
        }

        .slick-slider.slide1 {
            .slick-list {
                .react-pdf__Page {
                    .react-pdf__Page__canvas {
                        width: 80% !important;
                        max-width: 700px;
                        height: auto !important;

                    }

                }
            }

            // order position 

            .slick-arrow {
                display: none!important;
            }

            .slick-current {
                .react-pdf__Page {
                    
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                }
            }

        }
    }
// preview pagination
    .preview-pdf-pagi {
        margin-top:24px;
        max-width: 100%;
        height: 60px;
        button.slick-arrow {
            display: none !important;
        }
        @media(min-width:768px){
            max-width: 80%;
        }
        .slick-list {
           
            overflow: hidden;
            .slick-current{
                border:1.5px solid #ffa500;
                .react-pdf__Page__annotations{
                    position: absolute;
                }
            }
            .react-pdf__Page {
                display: flex;
                flex-direction: column;
                .react-pdf__Page__textContent{
                    pointer-events: none;
                    z-index: -1;
                }
                .react-pdf__Page__canvas {
                   
                    min-width:50px;
                    width: 70%!important;
                    max-width: 50px;
                    height: auto!important;
                    margin:0 auto;
                    @media(min-width:768px){
                        max-width: 70px;
                    }
                }

            }
        }
    }

}

