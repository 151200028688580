/** @format */

.search-wrapper {
  justify-content: space-between;
  height: calc(100% - 52px);

  .search-head {
    @include p-large-bold;
    color: $base-black;
    margin-bottom: 16px;
  }

  .result-wrap {
    display: flex;
    align-items: center;
    color: $base-black;
    margin-bottom: 8px;
    transition: all 0.5s ease;

    &:last-child {
      margin-bottom: 0;
    }

    .result-image {
      height: 60px;
      width: 60px;
      border-radius: 8px;
      overflow: hidden;
      position: relative;

      img {
        height: 60px;
        width: 60px;
        object-fit: cover;
      }

      .img-hover-effect {
        height: auto;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 0.5s ease;
        background-color: rgba(0, 0, 0, 0.25);
        path {
          transition: all 0.5s ease;
        }
      }
    }

    &:hover {
      transition: all 0.5s ease;
      .result-image {
        .img-hover-effect {
          background-color: $primary1-yellow-500;
          path {
            stroke: #fff !important;
          }
        }
      }
    }
  }

  .result-text {
    @include p-large-medium;
    margin-left: 16px;
  }
}
