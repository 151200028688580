/** @format */
.tabbed-navigation .MuiTabs-root .MuiButtonBase-root {
  @media (max-width: 1440px) {
    font-size: 14px;
    padding-left: 16px;
    padding-right: 16px;
  }
}

.repairIt_text {
  @media (max-width: 1440px) {
    font-size: 16px;
  }
}
