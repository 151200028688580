/** @format */

.landing-login {
  background: linear-gradient(269.74deg, rgb(209, 209, 209) 31.71%, #d5d5d5 71.41%, #d3d3d3 99.77%);
  height: 100vh;
  overflow: auto;

  .bg-logo {
    padding-left: 56px;
    font-size: 0;
    @include media-min-lg {
      display: inline-block;
      position: relative;
      left: 150px;
      padding-left: 0;
    }
    @include media-sm {
      height: 56px;
      margin: 0;
      padding-left: 0;
      background: $primary1-yellow-500;
    }
    .logo_desktop {
      position: relative;
      font-size: 0;
      @include media-sm {
        display: none;
      }
    }
    .logo_mobile {
      @include media-min-sm {
        display: none;
      }
      @include media-sm {
        display: block;
        width: 100%;
        height: 100%;
        padding: 12px 24px;
      }
      svg {
        height: 33px;
        width: auto;
      }
    }
  }

  .landing-wrapper {
    @include flex;
    height: calc(100% - 165px);
    @include media-lg {
      flex-wrap: wrap;
    }
    @include media-sm {
      height: calc(100% - 76px);
      margin-top: 2rem;
      align-items: normal;
    }
  }

  .left-wrapper {
    flex-basis: 60%;
    padding: 0 80px 0 0;
    @include media-md {
      flex-basis: 100%;
      padding: 0 42px;
    }
    .landing-img {
      max-width: 100%;
      height: 100%;
      object-fit: contain;
      @include media-md {
        position: relative;
        top: 50%;
        height: auto;
        transform: translateY(-50%);
      }
    }
  }
  .right-wrapper {
    flex-basis: 40%;
    @include flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 56px 65px 0;
    @include media-md {
      flex-basis: 100%;
      padding: 24px;
    }
    .content-wrapper {
      max-width: 550px;
      @include media-md {
        flex-grow: 1;
        max-width: 100%;
      }
      h1 {
        @include media-xl {
          font-size: 40px;
          line-height: 55px;
        }
        @include media-md {
          font-size: 32px;
          line-height: 36px;
          letter-spacing: initial;
        }
      }
      p {
        margin-top: 24px;
        margin-bottom: 40px;
        font-size: 18px;
        line-height: 29px;
        letter-spacing: 0.14px;
        @include media-md {
          font-size: 18px;
          line-height: 29px;
          letter-spacing: initial;
          margin-bottom: 32px;
        }
      }
      button {
        max-width: max-content;
      }
    }
  }

  &.landing-login-javaFX {
    .landing-wrapper {
      letter-spacing: 0;
      font-size: 0;
    }
    .left-wrapper {
      padding: 0;
      height: 100%;
      padding-right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      .landing-img {
        display: block;
        margin: 0 auto;
      }
    }
    .right-wrapper {
      padding: 0;
      .row_field {
        margin: 0 0 2rem;
      }
      .content-wrapper {
        padding-bottom: 40px;
      }
    }
  }
}

.landing-footer {
  @include flex;
  a.link-text {
    font-size: 13px;
    line-height: 18px;
    letter-spacing: 0.01em;
    cursor: pointer;
    &:not(:last-child) {
      margin-right: 24px;
    }
  }
}
.landing-page-footer {
  margin-right: 56px;
  margin-bottom: 40px;
  position: absolute;
  bottom: 0;
  right: 0;
  @include media-md {
    position: inherit;
    margin: 30px 0 0 0;
  }
}
