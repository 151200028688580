/** @format */

.content-shareing-modal {
  z-index: 9999 !important;
}
.share-url-text {
  margin-top: 24px;
  position: relative;
  display: flex;
  align-items: center;
  .share-url-text-input {
    flex: auto;
    & > div {
      border-radius: 4px 0 0 4px;
    }
  }
  button {
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-left: 0;
    background-color: transparent;
    width: 58px;
    height: 58px;
    border-radius: 0 4px 4px 0;
  }
}
