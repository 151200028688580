/** @format */

.notification-wrapper {
  height: calc(100% - 52px);

  .notification-box {
    background-color: $primary2-sand-100;
    padding: 16px;
    border-radius: 8px;
    margin-bottom: 4px;
    cursor: pointer;
    &:last-child {
      margin-bottom: 0;
    }

    &.active {
      background: #ebe1cd;
    }

    &:hover {
      background-color: $primary2-sand-200;
    }
  }

  .notification-head {
    @include p-large-bold;
    color: $base-black;
    margin-bottom: 16px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    &.notification-present {
      padding-left: 20px;
      position: relative;
      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        height: 12px;
        width: 12px;
        margin-top: auto;
        margin-bottom: auto;
        background-color: $primary1-yellow-500;
        border-radius: 50%;
      }
    }
  }

  .notification-detail {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: $base-black-t40;
  }
}

.allRead {
  position: absolute;
  right: 60px;
}
.notification-layer-wrapper {
  height: calc(100% - 52px);
  margin-top: 40px;
  .notification-box {
    background-color: #faf4eb;
    padding: 16px;
    border-radius: 8px;
    margin-bottom: 8px;
    cursor: pointer;
    &:last-child {
      margin-bottom: 0;
    }
    &.unread {
      background: #ebe1cd;
    }
    &.active {
      background: #ebe1cd;
    }

    &:hover {
      background-color: $primary2-sand-200;
    }
  }

  .notification-head {
    font-size: 24px;
    color: $base-black;
    margin-bottom: 16px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    &.notification-present {
      padding-left: 20px;
      position: relative;
      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        height: 12px;
        width: 12px;
        margin-top: auto;
        margin-bottom: auto;
        background-color: $primary1-yellow-500;
        border-radius: 50%;
      }
    }
  }

  .notification-detail {
    font-weight: 500;
    font-size: 17px;
    line-height: 25px;
    margin-bottom: 16px;
    color: $neutrals-gray-800;
  }
  .notification_date {
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    color: $base-black-t40;
  }
}
