.links-widget {
    // background: linear-gradient(0deg, rgba(226, 226, 226, 0.6), rgba(226, 226, 226, 0.6)),
    //     linear-gradient(180deg, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.9) 100%),
    //     url(../../assets/imgs/image_marketing.png), #ffffff;
    // background-size: cover;
    .link-text {
        @include p-large-medium;
        max-width: 234px;
    }
}
