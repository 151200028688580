/** @format */

button {
  border: none;
  cursor: pointer;
  font-family: ContinentalStagSansW; // bese font
}

.btn_circle {
  overflow: hidden;
  width: 7rem;
  height: 7rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  @include transition(all, 0.3s, ease);

  &:active {
    transform: scale(.95);
  }
}

.primary {
  color: $base-white;
  background-color: $primary1-yellow-500;

  &:hover {
    background-color: rgba($primary1-yellow-500, 0.8);
  }
}

.black {
  color: $base-white;
  background-color: $base-black;

  &:hover {
    background-color: rgba($base-black, 0.8);
  }
}

.white {
  color: $base-black;
  background-color: $base-white;

  &:hover {
    background-color: rgba($base-white, 0.8);
  }
}

// for sub dashboard
.btn_text {
  display: flex;
  align-items: center;
  column-gap: 1.6rem;

  svg {
    font-size: 1.6rem; // icon size
  }

  span {
    font-size: 1.6rem;
    font-weight: 500;
  }

  &.text_right {
    button {
      order: 1;
    }
  }

  &.text_left {
    button {
      order: -1;
    }
  }
}

.btn_add-widget {
  position: absolute;
  bottom: 4.1rem;
  left: 8.1rem;
}