.onboarding-cover.custom-swipeable {
    background-color: rgba(#faf4eb, .7);
    backdrop-filter: blur(20px);

    &>div {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

    }

    .close-onboard {
        margin-left: auto;
        margin-right: 20px;
        margin-top: 20px;

        @media(min-width:900px) {
            margin-right: 40px;
            margin-top: 40px;
        }

    }

    .slick-slide {
        text-align: center;
    }

    .slide1 {
        .slick-dots {
            display: block;
            position: static;

            li {
                margin: unset;

                button {
                    padding: unset;
                    width: unset;
                    height: unset;

                    &::before {
                        height: 7px;
                        width: 7px;
                        border-radius: 50%;
                        background: rgba(0, 0, 0, 0.4);
                        font-size: unset;
                        font-family: unset;
                    }
                }

                &.slick-active {
                    width: 36px;

                    button {

                        &::before {
                            width: 27px;
                            background: #000000;
                            border-radius: 16px;
                        }



                    }
                }
            }
        }
    }

    .slide2 {
        margin-top: 40px;
    }

    .onboarding-wrapper {
        justify-self: center;
        width: 100%;
        padding: 20px;
        position: relative;
        overflow-y: auto;

        &::-webkit-scrollbar {
            display: none;
        }

        @media(min-width:900px) {
            padding: 20px 50px;
        }

        .onboard-image {
            width: 100%;
            max-width: 500px;
            margin: 0 auto;

            img {
                width: 100%;
            }
            @media(min-width:1600px){
                max-width: 992px;
            }
        }

        .onboard-content {
            width: 100%;
            max-width: 586px;
            text-align: center;
            margin: 0 auto;
            color: #000000;

            h3 {
                font-style: normal;
                font-weight: 400;
                font-size: 45px;
                line-height: 54px;
                margin-bottom: 24px;

            }
            p {
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 29px;
            }
        }

        .footer-btn {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: -8px;
            margin-top: 20px;
            &>* {
                margin: 8px;
            }
            @media(min-width:1600px){
                margin-top: 40px;
            }
            .trans-light {
                background-color: rgba(231, 226, 219, 1);
                color: #000000;
            }
            
        }
    }
}