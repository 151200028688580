.news_card {
    padding: 24px;
    max-width: 800px;
    max-height: 340px;
    background-color: $primary2-pastel-100;
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    height: 340px;
    justify-content: space-between;
    flex: 1 0 calc(50% - 24px);
    &:hover {
        background-color: $primary2-pastel-300;
        cursor: pointer;
    }
    .content-release {
        color: $base-black-t40;
        width: initial;
    }
    .content-title {
        margin-top: 1.6rem;
        width: initial;
        // white-space: nowrap;
        // text-overflow: ellipsis;
        // width: 300px;
        // overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
    .content-text {
        margin-top: 1.6rem;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
    .content-read {
        color: $base-black-t40;
        margin-top: 3rem;
    }
    &.news_card_normal {
        max-width: 400px;
        flex-basis: calc(25% - 24px);
        background-color: transparent;
    }
}

.createCollection {
    font-size: 16px;
    max-width: 600px;
    margin: auto;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    .text-area {
        resize: none;
        background-color: transparent;
        border: 1px solid $base-black-t40;
        border-radius: 8px;
        width: 100%;
        font: 400 17px/25px ContinentalStagSansW;
        color: #000000;
        padding: 16.5px 14px;
        margin: 28px 0 23px;
        &:hover,
        &:focus-visible {
            border-color: $primary1-yellow-500;
            outline: none;
        }
    }
}
