/** @format */

//root color
$c_nav_bg_hover: rgba(254, 165, 0, 0.25);

// import style files  *** and sort according to priority ****

@import './colors';

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

@import './mixin';
@import './mediaQuery';
@import './typography';

html {
  box-sizing: border-box;
  font-size: 62.5%; // 1 rem / 10px
  overflow: hidden;
  font-family: ContinentalStagSansW, sans-serif; // base font
}

body {
  width: 100vw;
  overflow-x: hidden;
  background-color: $base-black;
}

::-webkit-scrollbar {
  width: 14px;
  width: 0;
  background: transparent;
}
::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background: $base-black-t40;
  border-radius: 100px;
  background-clip: padding-box;
  border: 4px solid transparent;
  background: transparent;
}
::-webkit-scrollbar-corner {
  background: transparent;
}

@import './common';

// set base style here
@import './layout';
@import './navigation';
@import './components/index.scss';
@import './views/index.scss';
@import './widgets/index.scss';
@import './landing-login';
