/** @format */

$destop: 1280px;
.profile {
  margin-top: 5rem;
  .page_title {
    font-size: 3.5rem;
    line-height: 40.8px;
    margin-bottom: 3rem;
  }
  .page_subtitle {
    font-size: 1.5rem;
    margin-top: 1.5rem;
    line-height: 19px;
    color: #666;
  }
  .MuiGrid-container {
    grid-column-gap: 20px;
  }
  button[label='Reset'] {
    color: #000;
  }
  .btnWrapper {
    margin-top: 1.5rem;
  }
  .sub_head {
    font-size: 2.4rem;
    margin-top: 3rem;
    margin-bottom: 4rem;
    line-height: 30px;
    color: #000;
    font-weight: 400;
    &.two {
      margin-top: 6.5rem;
    }
    @media (min-width: $destop) {
      /* margin-top: 8rem; */
      margin-bottom: 2.2rem;
      &.two {
        margin-top: 8rem;
      }
    }
    &-big {
      font-size: 2.8rem;
    }
  }
  .profile_pic {
    .avatar-box {
      border-radius: 10px;
      width: 19.2rem;
      height: 19.2rem;
    }
  }
  .map-area {
    position: relative;
    width: 100%;
    height: 500px;
  }
  .lbl_txt {
    font-size: 1.5rem;
    @media (min-width: $destop) {
      font-size: 18px;
      line-height: 29px;
      font-weight: 500;
      color: #000;
    }
  }
  .map_marker {
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 50% 50% 50% 0;
    border: 4px solid rgb(5, 18, 206);
    width: 20px;
    height: 20px;
    transform: rotate(-45deg);
    &::after {
      position: absolute;
      content: '';
      width: 10px;
      height: 10px;
      border-radius: 50%;
      top: 50%;
      left: 50%;
      margin-left: -5px;
      margin-top: -5px;
      background-color: rgb(5, 18, 206);
    }
  }
  .autoComplete-wrapper {
    .autoComplete {
      width: 100%;
    }
  }
  .instruction {
    font-size: 14px;
    margin-top: 1rem;
    color: rgb(107, 107, 107);
    &.fulfilled {
      color: rgb(37, 189, 4);
    }
  }
  .validationResult {
    margin-top: 15px;
  }
  .placeholderMsg,
  .errorMsg,
  .successMsg {
    font-size: 14px;
    text-align: center;
  }
  .errorMsg {
    color: $red-300;
  }
  .successMsg {
    color: $green-800;
  }
  .collection {
    padding: 25px;
    border-radius: 15px;
    height: 100%;
    min-height: 250px;
    font-size: 15px;
    position: relative;
    .collection_body {
      display: flex;
      margin-top: 10px;
      .date {
        margin-left: 15px;
      }
    }
    .cta_btn {
      align-items: center;
      position: absolute;
      bottom: 15px;
      right: 15px;
    }
  }
  .layer_header {
    display: flex;
    justify-content: space-between;
  }
  .map_Wrapper {
    position: relative;
  }
  .map_searchBox {
    position: absolute;
    box-sizing: border-box;
    width: 65%;
    padding: 0;
    border-radius: 3px;
    box-shadow: rgb(0 0 0 / 30%) 0 2px 6px;
    font-size: 14px;
    outline: none;
    z-index: 1;
    left: 3%;
    top: 10px;
  }
}

.profile-nav {
  .MuiGrid-item {
  }
}

.profile_upload {
  height: 120px;
  background: $base-black-t5;
  border-radius: 15px;
  color: rgba(0, 0, 0, 0.4);
  padding: 40px;
  position: relative;
  @media (min-width: 1280px) {
    height: 192px;
  }
  .file_uploader {
    left: 0;
    height: 100%;
    position: absolute;
    width: 100%;
    top: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    z-index: 2;
  }
  .upload {
    font-size: 17px;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
  }
}

.upload_guide {
  font-size: 1.4rem;
  line-height: 1.5;
  @media (max-width: 1279px) {
    padding-top: 20px;
    padding-left: 80px;
  }
}
.upload_error {
  font-size: 1.4rem;
  color: #e2000e;
  @media (max-width: 1279px) {
    padding-left: 80px;
  }
}
.onlySP {
  @media (min-width: $destop) {
    display: none;
  }
}
.onlyPC {
  @media (max-width: 1279px) {
    display: none;
  }
}

.interest-topic-item {
  .checkboxLabel {
    input:checked ~ svg {
      rect {
        fill: $primary1-yellow-500;
      }
      path {
        fill: white;
      }
    }
  }
}
