/** @format */

.img-fluid {
  max-width: 100%;
  height: auto;
}
.multiselect-dropdown-no-margin .multiselect-dropdown {
  margin: 0 !important;
}
.read-only {
  opacity: 0.8;
  filter: grayscale(1);
  cursor: not-allowed;
  pointer-events: none;
}
