/** @format */

.admin-user.admin-topics {
  .page_header-buttons {
    @media (max-width: 767px) {
      position: fixed;
      bottom: 16px;
      right: 16px;
      z-index: 9;
      button.outlined-button {
        background-color: #fff;
      }
    }
  }
}
.admin-user.admin-topics {
  overflow: hidden;
  overflow-y: scroll;
}
.admin-user .topic-list {
  display: flex;
  flex-direction: column;
  .heading-column {
    position: sticky;
  }
  .heading-column .user-displayName,
  .topic-item.sticky > .list-item {
    position: sticky;
    top: 38px;
    z-index: 9;
  }
  .topic-item .user-displayName {
    justify-self: center;
  }
  .heading-column .brand,
  .topic-item .brand {
    justify-self: center;
  }
  .heading-column .user-group,
  .topic-item .user-group {
    justify-self: center;
  }
  .heading-column .action,
  .topic-item .action {
    justify-self: center;
    position: relative;
    overflow: visible;
    button:not(:last-child) {
      margin: 0;
    }
    .action-menu {
    }
    button.action-menu-item {
      width: 100%;
      span {
        width: 52px;
      }
    }
  }
  .topic-item .list-item {
    span.role-list {
      width: 140px;
    }
    @media (max-width: 767px) {
      grid-template-columns: 4fr 1fr 1fr;
      > div:nth-child(n + 2):nth-child(-n + 6) {
        display: none;
      }
      .name {
        gap: 8px;
        flex-wrap: wrap;
        justify-content: flex-start;
      }
      .content-action-menu-wrap {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .topic-item-level {
    margin-top: 8px;
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 8px;
    padding-left: 40px;
    .list-item {
      background: rgba(255, 255, 255, 0.6);
      z-index: 8;
    }

    &.topic-item-level3 {
      padding-left: 40px;
      .list-item {
        background: rgba(255, 255, 255, 0.3);
      }
    }
  }
}

.adminTopics-editor {
  padding: 53px 340px 53px 56px;
  height: 100%;
  max-height: calc(100vh - 90px);
  overflow: hidden;
  overflow-y: scroll;
  @media (max-width: 767px) {
    padding: 24px;
  }
  .adminTopics-editor-main-content {
    margin-top: 115px;
    @media (max-width: 767px) {
      margin-top: 32px;
    }
  }
  &-title {
    font-size: 24px;
    @media (max-width: 767px) {
      font-display: 18px;
    }
  }
  .adminTopics-editor-form {
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    @media (max-width: 767px) {
      gap: 16px;
    }
  }
  .content-field-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 32px;
    @media (max-width: 767px) {
      flex-direction: column;
      align-items: stretch;
      gap: 8px;
    }
    .field-label {
      flex: 1 1 0;
      @media (max-width: 767px) {
        flex: 1;
      }
    }
    .field-input {
      position: relative;
      flex: 3 1 0;
      @media (max-width: 767px) {
        flex: 1;
      }
      .text-fields {
        width: 100%;
      }
      .autoComplete-wrapper.light,
      .multi-select-tree-light,
      .field-input-select {
        width: 50%;
        @media (max-width: 767px) {
          width: 100%;
        }
      }
      .text-area {
        width: 100%;
        padding: 16.5px 14px;
        background: none;
        color: #000;
        border-radius: 8px;
        font: 500 16px/21px ContinentalStagSansW;
      }
    }
  }
  &-buttons {
    position: absolute;
    bottom: 38px;
    right: 56px;
    display: flex;
    gap: 16px;
    .customButton {
      color: black;
      @media (max-width: 767px) {
        &:not(.customButton-cancel) {
          font-size: 0;
        }
        span.MuiButton-endIcon {
          margin: 0;
        }
      }
    }
    .customButton.dark {
      color: white;
      path {
        fill: white;
      }
      &:hover {
        color: black;
        path {
          fill: black;
        }
      }
    }
  }
}

.delete-modal-inner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  border: 2px solid #000;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14),
    0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  padding: 16px 24px;
}

@media (min-width: 1280px) {
  .admin-topics.admin-user .user-list.extranet-user-list .heading-column,
  .admin-topics.admin-user .user-list.extranet-user-list .list-item {
    grid-template-columns: 2fr 32px 32px 2fr 1fr 1fr 60px 32px;
  }
  .admin-topics.admin-user.admin-topics-nav-open .user-list.extranet-user-list .heading-column,
  .admin-topics.admin-user.admin-topics-nav-open .user-list.extranet-user-list .list-item {
    grid-template-columns: 2fr 0 0 2fr 60px 32px;
  }

  .admin-topics.admin-user .user-list.extranet-user-list .topic-item-level .list-item {
    grid-template-columns: 2fr 32px 2fr 1fr 1fr 60px 32px;
  }
  .admin-topics.admin-user.admin-topics-nav-open .user-list.extranet-user-list .topic-item-level .list-item {
    grid-template-columns: 2fr 0 2fr 60px 32px;
  }

  .admin-topics.admin-user .user-list.extranet-user-list .topic-item-level3 .list-item {
    grid-template-columns: 2fr 2fr 1fr 1fr 60px 32px;
  }
  .admin-topics.admin-user.admin-topics-nav-open .user-list.extranet-user-list .topic-item-level3 .list-item {
    grid-template-columns: 2fr 2fr 60px 32px;
  }
}
