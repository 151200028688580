.error_page {
  z-index: 10;
  margin: -40px -56px;
  background: rgb(209, 209, 209);
  background: url("../../assets/imgs/background_404.png");
  // background: linear-gradient(269deg, rgb(209, 209, 209) 0%, rgb(213, 213, 213) 50%, rgb(211, 211, 211) 100%);
  position: relative;
  min-height: 100vh;
  border-radius: 48px 0 0 48px;
  .bg-logo {
    height: 13.6rem;
    margin-left: 5rem;
    @include flex;
    justify-content: flex-start;
    align-items: center;
  }
  .error_wrapper {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 600px;
    p {
      margin-top: 1.5rem;
    }
    button {
      margin-top: 1.5rem;
    }
  }
}
