/** @format */

.card-container {
  padding: 24px 24px 90px;
  background-color: $base-white;
  border-radius: 24px;
  height: 100%;
  @media screen and (max-width: 599px) {
    overflow: hidden;
  }
  h3 {
    font: 500 28px/36px ContinentalStagSansW-M;
    margin-bottom: 16px;
    @media (max-width: 1440px) {
      font-size: 24px;
    }
  }
  &.no-cta {
    padding: 24px;
  }
}
.widget_cta_button {
  position: absolute;
  bottom: 24px;
  right: 24px;
}

@import './news.scss';
@import './marketing.scss';
@import './search.scss';
@import './notification.scss';
@import './links-widget.scss';
@import './cardLayer.scss';
@import './repairIt.scss';
