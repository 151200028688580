@import '~material-design-icons-iconfont/dist/material-design-icons';

@font-face {
  font-family: "ContinentalStagSansW";
  src: url("../assets/fonts/contiFonts/Continental_Stag_Sans_Book.otf") format("opentype");
}

@font-face {
  font-family: "ContinentalStagSansW-M";
  src: url("../assets/fonts/contiFonts/ContinentalStagSans-Med.otf") format("opentype");
}

h1 {
  // color: $neutrals-gray-900;
  font: normal 400 60px/72px ContinentalStagSansW;
  letter-spacing: 0.02em;
  @include media-md {
    font: normal 400 40px/48px ContinentalStagSansW;
  }
}

h2 {
  font: normal 400 45px/54px ContinentalStagSansW;
  @include media-md {
    font: normal 500 32px/36px ContinentalStagSansW;
  }
}

h3 {
  font: normal 500 28px/36px ContinentalStagSansW;
  @include media-md {
    font: normal 500 20px/26px ContinentalStagSansW;
  }
}

h4 {
  // color: $neutrals-gray-900;
  font: normal 500 21px/28px ContinentalStagSansW;
  @include media-md {
    font: normal 500 17px/22px ContinentalStagSansW;
  }
}

h5 {
  color: $neutrals-gray-900;
  font: normal 700 16px/21px ContinentalStagSansW;
  @include media-md {
    font: normal 700 16px/21px ContinentalStagSansW;
  }
}

h6 {
  color: $neutrals-gray-900;
  font: normal 500 16px/18px ContinentalStagSansW-M;
  letter-spacing: 0.01em;
  @include media-md {
    font: normal 500 16px/21px ContinentalStagSansW;
  }
}

.caption {
  color: $neutrals-gray-900;
  font: normal 700 10px/13px ContinentalStagSansW;
  letter-spacing: 0.115em;
  text-transform: uppercase;
  @include media-md {
    font: normal 700 11px/14px ContinentalStagSansW;
  }
}

p {
  font: normal 400 18px/29px ContinentalStagSansW;
  color: $base-black;
}

.p-small {
  font: normal 400 13px/18px ContinentalStagSansW;
}

.p-large-medium {
  font: normal 500 16px/21px ContinentalStagSansW;
}

@mixin p-large-medium {
  font: normal 500 16px/21px ContinentalStagSansW;
}

.p-small-medium {
  font: normal 500 14px/18px ContinentalStagSansW;
}

@mixin p-small-medium {
  font: normal 500 14px/18px ContinentalStagSansW;
}

.p-large-bold {
  font: normal 700 16px/21px ContinentalStagSansW;
}

@mixin p-large-bold {
  font: normal 700 16px/21px ContinentalStagSansW;
}

.p-small-bold {
  font: normal 700 14px/18px ContinentalStagSansW;
}

.caption-large {
  font: normal 500 13px/17px ContinentalStagSansW;
}

.caption-small {
  font: normal 500 12px/16px ContinentalStagSansW;
}
