// Base
$base-white: #ffffff;
// White Transparency
$base-white-t10: rgba(255, 255, 255, 0.1);
$base-white-t15: rgba(255, 255, 255, 0.15);
$base-white-t20: rgba(255, 255, 255, 0.2);
$base-white-t30: rgba(255, 255, 255, 0.3);
$base-white-t40: rgba(255, 255, 255, 0.4);
$base-white-t50: rgba(255, 255, 255, 0.5);
$base-white-t60: rgba(255, 255, 255, 0.6);
$base-white-t64: rgba(255, 255, 255, 0.64);

$base-black: #000000;
// Black Transparency
$base-black-t5: rgba(0, 0, 0, 0.05);
$base-black-t8: rgba(0, 0, 0, 0.08);
$base-black-t10: rgba(0, 0, 0, 0.1);
$base-black-t25: rgba(0, 0, 0, 0.25);
$base-black-t30: rgba(0, 0, 0, 0.3);
$base-black-t40: rgba(0, 0, 0, 0.4);

// Neutrals - Gray
// $neutrals-gray-50: #F7F7F7;
$neutrals-gray-50-t60: rgba(247, 247, 247, 0.6);
// $neutrals-gray-100: #EDEDED;
// $neutrals-gray-200: #D9D9D9;
// $neutrals-gray-300: #CCCCCC;
// $neutrals-gray-400: #BFBFBF;
// $neutrals-gray-500: #B3B3B3;
// $neutrals-gray-600: #8F8F8F;
// $neutrals-gray-700: #666666;
// $neutrals-gray-800: #404040;
$neutrals-gray-800-t20: rgba(64, 64, 64, 0.2);
// $neutrals-gray-900: #1F1F1F;

// NEUTRALS - New
$neutrals-gray-50: #fafafa;
$neutrals-gray-100: #f0f0f0;
$neutrals-gray-200: #e6e6e6;
$neutrals-gray-300: #dbdbdb;
$neutrals-gray-400: #cdcdcd;
$neutrals-gray-500: #b3b3b3;
$neutrals-gray-600: #969696;
$neutrals-gray-700: #6b6b6b;
$neutrals-gray-800: #404040;
$neutrals-gray-900: #1f1f1f;

// Primary 1 - Orange
$primary1-orange-100: #ffe6b6;
$primary1-orange-300: #fec55b;
// $primary1-orange-500: #FFA500;
$primary1-orange-500-t15: rgba(254, 165, 0, 0.15);
$primary1-orange-500-t20: rgba(254, 165, 0, 0.2);
$primary1-orange-500-t25: rgba(254, 165, 0, 0.25);
$primary1-orange-600: #e38702;
$primary1-orange-800: #8b5301;
$primary1-orange-900: #321e00;

// Primary 2 - Sand
$primary2-sand-100: #f9f2e5;
$primary2-sand-200: #e3d9c5;
$primary2-sand-300: #cac1af;
$primary2-sand-500: #9b907a;
$primary2-sand-700: #6c5e44;
$primary2-sand-900: #3d2d0e;

// Feedback
// Red
$red-100: #ffe7e8;
$red-300: #e2000e;
// $red-500: #B5000B;
$red-500-t10: rgba(181, 0, 11, 0.1);
$red-700: #5c0006;

// Red - New
$red-200: #ffe0e1;
$red-500: #ff2d37;
$red-800: #9e0008;

// Green
$green-100: #d8fdc2;
$green-300: #77b850;
$green-300-t24: rgba(142, 196, 109, 0.24);
$green-500: #378a04;
$green-700: #1c4502;

// Green - New
$green-200: #daf7d9;
$green-500: #2db928;
$green-800: #1c7118;

// Yellow
$yellow-100: #fff9db;
$yellow-300: #fff0a3;
$yellow-500: #fee45b;
$yellow-700: #e6cb42;

// CONTINENTAL YELLOW - New
$primary1-yellow-100: #ffe6bf;
$primary1-yellow-300: #ffba55;
$primary1-yellow-500: #ffa500;
$primary1-yellow-700: #e38702;
$primary1-yellow-800: #8f5600;
$primary1-yellow-900: #603a00;

// PASTEL YELLOW - New
$primary2-pastel-50: #fffdfa;
$primary2-pastel-100: #faf4eb;
$primary2-pastel-300: #f4e7d4;
$primary2-pastel-500: #f4cf9b;
$primary2-pastel-700: #e0b579;
$primary2-pastel-900: #8a6129;

// Blue
$blue-100: #def3fc;
$blue-300: #94d5f2;
$blue-500: #3fb5e8;
$blue-700: #126a90;

// Blue - New
$blue-200: #d1f4ff;
$blue-500: #00a5dc;
$blue-800: #00678a;

// Secondary
// Secondary 1 - BlueGray
$secondary1-bluegray-100: #eaf2f3;
$secondary1-bluegray-300: #c0d7da;
$secondary1-bluegray-500: #8ca3a6;
$secondary1-bluegray-700: #586f72;
$secondary1-bluegray-900: #243b3e;

// Secondary1 - Blue - new
$secondary1-blue-100: #e5edf7;
$secondary1-blue-300: #bcc8d6;
$secondary1-blue-500: #8ca3c0;
$secondary1-blue-700: #476180;
$secondary1-blue-900: #212d3b;

//Secondary 2 - Grape
$secondary2-grape-100: #f0e6ec;
$secondary2-grape-300: #c1afbd;
$secondary2-grape-500: #a87e9f;
$secondary2-grape-700: #75586f;
$secondary2-grape-900: #6f2760;

// Secondary2 - Green - New
$secondary2-green-100: #daebe6;
$secondary2-green-300: #c5d3cf;
$secondary2-green-500: #96b0a9;
$secondary2-green-700: #5a776f;
$secondary2-green-900: #36534b;

// Secondary 3 - GrayGreen
$secondary3-graygreen-100: #e8eae8;
$secondary3-graygreen-300: #bac1ba;
$secondary3-graygreen-500: #939a93;
$secondary3-graygreen-700: #6c746c;
$secondary3-graygreen-900: #454d45;

// Secondary3 - Stone - New
$secondary3-stone-100: #e8eae8;
$secondary3-stone-300: #e8eae8;
$secondary3-stone-500: #939a93;
$secondary3-stone-700: #6c746c;
$secondary3-stone-900: #454d45;

// Secondary 4 - GrayGreen
$secondary4-olive-100: #eef0e8;
$secondary4-olive-300: #d3d6c6;
$secondary4-olive-500: #9fa77c;
$secondary4-olive-700: #76804b;
$secondary4-olive-900: #4d591a;

// Secondary4 - Olive - New
$secondary4-olive-100: #eef0e8;
$secondary4-olive-100: #d3d6c6;
$secondary4-olive-100: #9fa77c;
$secondary4-olive-100: #76804b;
$secondary4-olive-100: #4d591a;

// Secondary 5 - GrayGreen
$secondary5-apricot-100: #ffede3;
$secondary5-apricot-300: #efcfbd;
$secondary5-apricot-500: #d6ac94;
$secondary5-apricot-700: #cf8e6b;
$secondary5-apricot-900: #a66846;
