/** @format */

.header {
  display: flex;
  background: $primary1-yellow-500;
  position: sticky;
  top: 0;
  // align-items: center;
  // padding-left: 0rem;
  // padding-right: 1rem;
  // justify-content: space-between;
  @include media-lg {
    background-color: $primary1-yellow-500;
    justify-content: space-between;
    align-items: center;
  }
  // .main_logo {
  //   height: 8rem;
  // }
  .mobile_logo_txt {
    font-weight: 700;
    font-size: 1.2rem;
    @include media-lg {
      margin-right: 16px;
    }
  }
  svg {
    height: 20px;
    width: 130px;

    @include media-min-lg {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

// content
.containerWrapper {
  position: relative;
  margin: 0 auto;
  width: 100%;
  // min-height:60rem;
  height: 100vh;
  // height: calc(var(--vh, 1vh) * 100);
  // font-size: 2rem;
}

.helpPage {
  .containerWrapper {
    height: auto !important;
  }
}
.containerInner {
  display: flex;
  background-color: $neutrals-gray-900;
  @include media-lg {
    display: block;
  }
  .PrivateSwipeArea-root {
    // display: none;
    width: 100%;
    @include media-lg {
      background: #f9f2e5;
      border-radius: 20px 20px 0 0;
      &::after {
        content: '';
        width: 50px;
        height: 6px;
        background-color: #ccc;
        border-radius: 3px;
        position: absolute;
        top: 8px;
        left: calc(50% - 25px);
      }
    }
  }
}

.contentBox {
  z-index: 1000;
  position: absolute;
  height: 100%;
  left: 0;
  top: 0;
  background: $primary2-pastel-100;
  box-shadow: 4px 8px 32px 4px rgba(50, 30, 0, 0.5);
  border-radius: 48px 0px 0px 48px;
  @include transition(transform, 0.5s, ease);
  @include media-sm {
    border-radius: 2rem 2rem 0px 0rem;
  }
}
.contentBoxInner {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  overflow: auto;
  // padding: 5rem 3rem 0rem 5rem;
  padding: 40px 56px;
  padding-bottom: 0;
  &::-webkit-scrollbar {
    display: none;
  }
  @include media-sm {
    width: 100%;
    padding: 5rem 1.5rem 1rem 1.5rem;
  }
}
.contentBoxInnerOpen {
  width: 50%;
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  overflow: auto;
  padding: 40px 56px 0;
  &::-webkit-scrollbar {
    display: none;
  }
  @include media-sm {
    width: 100%;
    padding: 5rem 3rem 0rem 3rem;
  }
}
.cardBox {
  position: fixed;
  height: 100%;
  right: 0;
  top: 0;
  background: $base-white;
  box-shadow: 0px 4px 66px rgba(0, 0, 0, 0.5);
  border-radius: 4rem 0px 0px 5rem;
  z-index: 1;
  @include transition(transform, 0.5s, ease);
}
.cardBoxInner {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  padding: 10rem 3rem 0rem 5rem;
  &::-webkit-scrollbar {
    display: none;
  }
}

.btnToggle {
  // border-radius: 5rem;
  // height: 8.8rem;
  // width: 8.8rem;
  position: absolute !important;
  bottom: 0;
  left: 0;
  @include media-sm {
    margin: 0 !important;
    bottom: auto;
    top: 10px;
    left: 10px;
    height: 4.8rem !important;
    width: 4.8rem;
    span {
      height: 4.8rem !important;
      width: 4.8rem !important;
    }
  }
  // cursor: pointer;
  // border: none;
  // display: flex;
  // align-items: center;
  // transition: 0.3s ease;
  // box-shadow: 0px 5px 13px rgba(0, 0, 0, 0.1), 0px 20px 40px rgba(0, 0, 0, 0.12);
  // background-color: $primary2-sand-100;
  // padding: 0.3rem;
  // &:hover,
  // &.active {
  //   background-color: $primary1-yellow-500;
  // }

  // .btnToggle_icon {
  //   width: 8rem;
  //   height: 8rem;
  //   border-radius: 50%;
  //   background-color: $base-black;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;

  //   svg {
  //     color: $base-white;
  //     font-size: 2rem;
  //   }
  // }
  // .btnToggle_text {
  //   margin-left: 1rem;
  //   font-size: 1.2rem;
  // }
  // @include media-sm {
  //   bottom: auto;
  //   top: 0px;
  //   left: 10px;
  //   height: 4.8rem;
  //   width: 4.8rem;
  //   .btnToggle_icon {
  //     width: 4rem;
  //     height: 4rem;
  //     svg {
  //       color: $base-white;
  //       font-size: 1rem;
  //     }
  //   }
  // }
} // end btnToggle

// .cardBtnToggle {
//   border-radius: 5rem;
//   // height: 4.8rem;
//   // width: 4.8rem;
//   position: absolute;
//   top: 36px;
//   left: 36px;
//   // cursor: pointer;
//   // border: none;
//   // display: flex;
//   // align-items: center;
//   // transition: 0.3s ease;
//   // box-shadow: 0px 5px 13px rgba(0, 0, 0, 0.1), 0px 20px 40px rgba(0, 0, 0, 0.12);
//   // background-color: transparent;
//   // padding: 0.3rem 1.5rem 0.3rem 0.3rem;
//   // &:hover,
//   // &.active {
//   //   background-color: $primary1-yellow-500;
//   // }

//   .btnToggle_icon {
//     width: 4rem;
//     height: 4rem;
//     border-radius: 50%;
//     // background-color: $base-black;
//     display: flex;
//     align-items: center;
//     justify-content: center;

//     svg {
//       color: $base-black;
//       font-size: 2rem;
//     }
//   }
//   .btnToggle_text {
//     margin-left: 0rem;
//     font-size: 1.4rem;
//   }
// } // end btnToggle

.test {
  width: 500px;
  background-color: orange;
  height: 1000px;
}

//Avatar
.avatar-box {
  width: 2.4rem;
  height: 2.4rem;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  // background-color: #aaa;
  svg {
    width: 100%;
    height: 100%;
  }
  span.first-letter {
    color: $base-white;
    @include middle-box;
    font-size: 1.2rem;
    text-transform: uppercase;
  }
  img {
    display: block;
    aspect-ratio: 1 / 1;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.drawer_Dashbaord {
  top: 140px !important;
  // .MuiBackdrop-root {
  //   display: none;
  // }
  .MuiDrawer-paper {
    background-color: #f9f2e5 !important;
    border-radius: 20px 20px 0 0;
    top: 140px;
    padding: 5rem 3rem;
    @include media-lg {
      padding: 5.2rem 1.6rem 0;
    }
  }
}
