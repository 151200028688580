/** @format */

.market_widget {
  &.slick-slider {
    height: 100%;
    // display: flex;
    // align-items: flex-end;
    @media screen and (max-width: 599px) {
      display: block;
      padding-top: 31px;
    }
  }
  .previous {
    position: absolute;
    top: 0;
    right: 40px;
    margin: 0;
    @media screen and (max-width: 599px) {
      display: none;
    }
  }
  .next {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    @media screen and (max-width: 599px) {
      display: none;
    }
  }
  .slick-dots {
    top: 10px;
    width: max-content;
    bottom: auto;
    left: calc(50% + 24px);
    max-width: 200px;
    white-space: nowrap;
    overflow: auto;
    &::-webkit-scrollbar {
      display: none;
    }
    @media screen and (max-width: 599px) {
      top: 0;
      left: 0;
      right: 0;
      display: flex !important;
      justify-content: center;
      width: 100%;
      max-width: 100%;
    }
    li {
      margin: 0;
      button {
        &:before {
          font-size: 8px;
          opacity: 0.1;
        }
      }
    }
    .slick-active {
      width: 30px;
      button {
        width: 30px;
        &:before {
          width: 30px;
          background: #000;
          height: 7px;
          margin: 6px 0;
          border-radius: 5px;
          content: '';
          opacity: 1;
        }
      }
    }
  }
}
// .disable{ opacity: 0.2;}
// .slick-prev{
//     left: 91%;
//     top: 5%;
//     z-index: 1;
// }
// .slick-next{
//     top: 5%;
//     right: 2%;z-index: 1;
// }
// .slick-prev:before, .slick-next:before{
//     color: #000;
//     font-size: 30px;
// }
// .slick-prev:before
// {
//     content: '←';
// }
// .slick-next:before
// {
//     content: '→';
// }
// }
