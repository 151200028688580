.ck {
    --ck-image-style-spacing: 1.5em;
    --ck-inline-image-style-spacing: calc(var(--ck-image-style-spacing) / 2);
    --ck-table-column-resizer-position-offset: calc(var(--ck-table-column-resizer-width) * -0.5 - 0.5px);
    --ck-table-column-resizer-width: 7px;
    --ck-todo-list-checkmark-size: 16px;
    --ck-z-default: 1;
    --ck-color-base-background: transparent;
    --ck-color-base-foreground: transparent;
    --ck-color-toolbar-background: transparent;
    --ck-color-toolbar-border: transparent;
    --ck-resizer-border-width:0;
    --ck-color-base-border: rgba(0, 0, 0, 0.3);
    --ck-border-radius: 8px;
    --ck-spacing-large: 5px;
    --ck-drop-shadow: 0px 7px 24px 0px rgba(0, 0, 0, 0.15);
    --ck-balloon-border-width: 0;
    --ck-inner-shadow: 0;
    --ck-focus-ring: 1px solid #ffa500;

    .ck-rounded-corners,
    .ck-editor__main > .ck-editor__editable,
    .ck-editor__main > .ck-editor__editable.ck-rounded-corners {
        border-top-right-radius: var(--ck-border-radius) !important;
        border-top-left-radius: var(--ck-border-radius) !important;
        min-height: 97px;
    }

    .ck-rounded-corners .ck-tooltip .ck-tooltip__text,
    .ck-tooltip .ck-tooltip__text.ck-rounded-corners {
        color: #fff;
    }

    .ck-toolbar {
        margin-bottom: 1rem !important;
        border: none !important;

        &.ck-toolbar_grouping > .ck-toolbar__items {
            flex-wrap: wrap;
        }
    }

    .ck-editor__main > .ck-editor__editable {
        padding: 5px 14px;
        font-size: 16px;
        line-height: 25px;

        p,
        ul,
        ol {
            font-size: 16px;
            color: #000;
        }

        ul,
        ol {
            font-size: 16px;
            padding-left: 15px;
        }

        a {
            color: #ffa500;
            text-decoration: underline;
        }

        &:hover {
            border-color: #8f5600;
        }

        &:active,
        &:focus {
            border-color: #ffa500;
            caret-color: #ffa500;
        }
    }

    .ck-balloon-panel {
        z-index: 1500 !important;
    }
}
