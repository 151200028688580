#content {
  width: 100%;
}

.layoutJSON {
  background: #ddd;
  border: 1px solid black;
  margin-top: 10px;
  padding: 10px;
}

.columns {
  -moz-columns: 120px;
  -webkit-columns: 120px;
  columns: 120px;
}

.react-grid-item {
  box-sizing: border-box;
  @include media-md {
    &:first-child{
      margin-top: 0rem;
    }
    &:last-child{
      height: auto !important;
    }
  }
}

.sub_dashboard_wrapper {
  background-color: $base-black;
  border-radius: 24px;
  .react-grid-item:not(.react-grid-placeholder) {
    background: $base-white-t10;
    border-radius: 16px;
    @include transition(background, 0.5s, ease);
    &:hover,
    &:active {
      background: $base-white-t20;
      @include transition(background, 0.5s, ease);
    }
  }
  .react-grid-item.resizing {
    opacity: 0.9;
  }
  .react-grid-item.static {
    background: #cce;
  }
  .react-grid-item .text {
    font: 500 16px/18px ContinentalStagSansW-M;
    letter-spacing: 0.01em;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: grid;
    place-items: center;
    color: $base-white;
    text-transform: capitalize;
    word-break: break-all;
    padding: 10px;
  }

  .react-grid-item .minMax {
    font-size: 12px;
  }

  .react-grid-item .add {
    cursor: pointer;
  }

  .react-draggable {
    cursor: grab;

    &:active,
    &.react-draggable-dragging {
      cursor: grabbing;
    }
  }

  li b {
    font-size: 19px;
    line-height: 14px;
  }
}

.widget_layout {
  .cssTransforms {
    @include transition(all, 0.3s, ease);
  }
}
