/** @format */

//sub dashboard
.addWidgetPopup {
  width: 100%;
  max-width: 57.9rem;
  height: 100%;
  max-height: 57.9rem;
  background-color: #fff;
  box-shadow: 0px 15px 42px rgba(0, 0, 0, 0.85);
  border-radius: 24px;
  position: absolute;
  left: 7rem;
  bottom: 15rem;
  z-index: 33;
  padding: 4rem 6rem 0rem;
  overflow: hidden;
  @include transition(all, 0.25s, ease-out);

  &::after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 5rem;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
  }

  .close {
    position: absolute;
    right: 2rem;
    top: 2rem;
    color: $base-black;
    font-size: 2rem;
  }

  .addWidgetPopup_header {
    text-align: center;

    h4 {
      font-size: 2.4rem;
      color: $base-black;
      font-weight: 500;
    }

    p {
      margin-top: 0.8rem;
      font-size: 1.6rem;
      color: rgba($base-black, 0.5);
      font-weight: 500;
    }
  }

  .addWidgetPopup_centent-wrapper {
    margin-top: 6rem;
    display: flex;
    width: 100%;
    height: calc(100% - 141px);
    column-gap: 3rem;
    row-gap: 1rem;

    .addWidget_item {
      display: flex;
      flex-direction: column;
      height: 100%;
      flex: 1 1;

      .addWidget_title {
        font-size: 1.6rem;
        font-weight: 700;
        color: $base-black;
        margin-bottom: 2rem;
      }

      .MuiFormGroup-root {
        align-items: flex-start;
        margin-bottom: 5rem;
      }

      label {
        border-radius: 0.8rem;
        padding: 1.6rem;
        margin-right: 0;
        margin-left: 0;
        justify-self: flex-start;
        column-gap: 1rem;

        &:not(:last-child) {
          margin-bottom: 1rem;
        }

        span.MuiCheckbox-root {
          padding: 0;

          svg {
            font-size: 2.4rem;
            border-radius: 0.4rem;
          }
        }

        span.MuiTypography-root {
          font-size: 1.6rem;
          font-weight: 700;
          font-family: ContinentalStagSansW; // bese font
        }
      }

      &.active-item {
        label {
          color: $base-white;
          background-color: rgba(38, 38, 38, 1);

          span.MuiCheckbox-root svg {
            fill: $primary1-yellow-500;
          }
        }
      }

      &.inactive-item {
        label {
          color: $base-black;
          background-color: rgba(0, 0, 0, 0.06);

          span.MuiCheckbox-root svg {
            fill: transparent;
            background-color: #d9d9d9;
            font-size: 2rem;
          }
        }
      }
    }
  }
}

.resetBtn {
  box-shadow: none !important;
  outline: none !important;
  text-transform: inherit !important;
  &:hover {
    background-color: #111 !important;
  }
}
