$small-screen: 575px;
$medium-screen: 767px;
$large-screen: 899px;
$xl-screen: 1200px;

@mixin media-xl {
    @media screen and (max-width: $xl-screen) {
        @content;
    }
}

@mixin media-lg {
    @media screen and (max-width: $large-screen) {
        @content;
    }
}

@mixin media-md {
    @media screen and (max-width: $medium-screen) {
        @content;
    }
}

@mixin media-sm {
    @media screen and (max-width: $small-screen) {
        @content;
    }
}

@mixin media-min-xl {
    @media screen and (min-width: $xl-screen) {
        @content;
    }
}

@mixin media-min-lg {
    @media screen and (min-width: $large-screen) {
        @content;
    }
}

@mixin media-min-md {
    @media screen and (min-width: $medium-screen) {
        @content;
    }
}

@mixin media-min-sm {
    @media screen and (min-width: $small-screen) {
        @content;
    }
}
